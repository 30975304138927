import { useMutation } from "react-query"
import { request } from "../api/fetch"

export const useBuyPlays = () => {
    const buyPlay = async ({product_type, count, user_id}) => {
        const { data } = await request.post(`/trivia/play/buy`, {product_type, count, user_id})
        return data
    }

    const mutation = useMutation({
        mutationFn: buyPlay,
        mutationKey:["buy plays"]
    })
    return mutation
}