import React, { useContext } from "react";
import Leads from "./components/shared/Leads";
import DailyStats from "./components/shared/DailyStats";
import { Stack, Box } from "@mui/material";
import Layout from "../../layout/Layout";
import Visitor from "./components/shared/Visitor";
import ConversionRate from "./components/shared/ConversionRate";
import TimeRate from "./components/shared/TimeRate";
import { VersionContext } from "../../../context/VersionContext";
import { useGetGamePlayStats } from "../../hooks/fetch";
function Stats() {
  const { adminVersion, quiz, quizAPIData } = useContext(VersionContext);
  console.log({ quizAPIData });
  const gameStats = useGetGamePlayStats({
    version: adminVersion,
    quizId: quiz?.id,
  });
  return (
    <Layout>
      <div>
        <Stack
          mb={3}
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          gap={3}
          fullWidth
          flexWrap="wrap"
        >
          <Visitor
            visitor={gameStats?.data?.totalSubscriber}
            title="Number of Subscribers"
          />
          <Visitor
            visitor={gameStats?.data?.totalPlayer}
            title="Number of Active Players"
          />
          <Visitor visitor={gameStats?.data?.totalVisitor} />
          <Visitor
            visitor={quizAPIData?.allQuiz?.length || ""}
            title="Number of Quiz"
          />
          <ConversionRate rate={gameStats?.data?.conversionRate || 0} />
          <TimeRate gameStats={gameStats} />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={4}
          fullWidth
          // flexWrap="wrap"
          justifyContent="space-between"
          overflow="scroll"
          margin="2px"
          padding="5px"
        >
          <Box sx={{ flex: "0.4" }}>
            <Leads gameStats={gameStats} />
          </Box>
          <Box sx={{ flex: "0.5", marginLeft: "20px" }}>
            <DailyStats gameStats={gameStats} />
          </Box>
        </Stack>
      </div>
    </Layout>
  );
}

export default Stats;
