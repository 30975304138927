import React from "react";
import IconCard from "../components/IconCard";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import WalletIcon from "@mui/icons-material/Wallet";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useWalletLogic from "../../wallet/useWalletLogic";
import useBuySpinLogic from "../../buySpin/useBuySpinLogic";
import dayjs from "dayjs";
function Resources() {
  const { walletBalance } = useWalletLogic();
  const { subscriptionStatus } = useBuySpinLogic();
  console.log({ walletBalance, subscriptionStatus });
  return (
    <div className=" mt-4 w-full justify-center flex">
      <div className="stats shadow ">
        <IconCard
          link="/qwiz/buy-spin"
          title={"Plays"}
          details={`${subscriptionStatus?.data?.subscription?.plays}`}
          icon={<FavoriteBorderIcon sx={{ color: "#61a641c4" }} />}
        />
        <IconCard
          link="/qwiz/wallet"
          title={"Wallet Balance"}
          details={`₦${walletBalance.data?.wallet?.balance || 0}`}
          icon={<WalletIcon sx={{ color: "#61a641c4" }} />}
        />
        <IconCard
          title={"Subscription Expiry"}
          details={`${dayjs(subscriptionStatus?.data?.subscription_end).format(
            "DD/MM/YYYY HH:mm"
          )}`}
          icon={<AccessTimeIcon sx={{ color: "#61a641c4" }} />}
          link={`http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web`}
        />
      </div>
    </div>
  );
}

export default Resources;
