import React from "react";
import Card from "./Card";
import { Typography } from "@mui/material";
function Visitor({ title = "Number of Visitors", visitor }) {
  return (
    <Card
      title={title}
      fontSize={12}
      sx={{
        minWidth: "150px",
        maxWidth: "300px",
        padding: "10px 20px",
        height: "150px",
      }}
    >
      <Typography variant="h4" align="center" fontSize={32}>
        {visitor}
      </Typography>
    </Card>
  );
}

export default Visitor;
