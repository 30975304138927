import { Box } from "@mui/material";
function CardLayout({ children }) {
  return (
    <Box
      sx={{
        padding: "15px",
        minHeight: "calc(100vh - 220px)",
        backgroundColor: "#F8F8F8",
        marginTop: "40px",
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
        "@media (max-width: 600px)": {
          marginTop: "10px",
        },
      }}
    >
      {children}
    </Box>
  );
}

export default CardLayout;
