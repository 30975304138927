import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Stack, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Instagram, Telegram, Twitter, WhatsApp } from "@mui/icons-material";

function Social() {
  return (
    <Container sx={{ backgroundColor: "F2F2F2" }}>
      <Stack
        sx={{
          minHeight: "150px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Reach Us On </Typography>
        <Stack direction="row" mt={2} sx={{ gap: "10px" }}>
          <Link
            // to={{ pathname: "https://www.facebook.com/9ijakids" }}
            to={"https://www.facebook.com/9ijakids"}
            target="_blank"
          >
            <FacebookIcon />
          </Link>
          <Link to="https://www.instagram.com/9ijakids" target="_blank">
            {" "}
            <Instagram />
          </Link>
          <Link to="https://twitter.com/9ijakids" target="_blank">
            {" "}
            <Twitter />
          </Link>
          <Link to="https://t.me/+B3jIp1KP4qE0MDk8" target="_blank">
            {" "}
            <Telegram />
          </Link>
          <Link
            to="https://chat.whatsapp.com/FKBkJUiIR0nCZOoN77ZxHp"
            target="_blank"
          >
            {" "}
            <WhatsApp />
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
}
export function FooterAddress() {
  return (
    <Container sx={{ background: "white", color: "#292929" }}>
      <Stack
        sx={{
          minHeight: "140px",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Typography variant="body2" align="center">
          Copyright © 2023 9ijakids. All rights reserved.
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{ display: "flex", gap: "12px" }}
        >
          Terms and conditions
          <Box component="span">
            <Typography variant="body2"> Privacy Policy</Typography>
          </Box>
        </Typography>
      </Stack>
    </Container>
  );
}
function Footer() {
  return (
    <Box>
      <Social />
      <FooterAddress />
    </Box>
  );
}

export default Footer;
