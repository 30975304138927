import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import Chatwoot from "./components/chatwoot/Chatwoot";
import Login from "./pages/login/Login";
import Logout from "./pages/logout/Logout";
import LandingPage from "./pages/landingPage/LandingPage";
import Register from "./pages/register/Register";
import PaymentMethod from "./pages/Subscription/PaymentMethod/PaymentMethod";
import AvailablePlan from "./pages/Subscription/AvailablePlan/AvailablePlan";
import Pending from "./pages/Subscription/PendingScreen/Pending";
import Welcome from "./pages/landingPage/Welcome";
import Homepage from "./pages/homepage/Homepage";
import Gameplay from "./pages/gamePlay/Gameplay";
import SubscriptionActivity from "./pages/Subscription/SubscriptionActivity/SubscriptionActivity";
import QwizLeaderboard from "./pages/QwizLeaderboard/Leaderboard";
import Terms from "../pages/howToPlay/Terms";
import Rules from "../pages/howToPlay/Rules";
import Security from "./pages/Security/Security";
import Winning from "./pages/Winnings/Winnings";
import Profile from "./pages/profile/Profile";
import HelpPage from "./pages/helpPage/HelpPage";
import RulesPage from "./pages/rulesPage/RulesPage";
import TAC from "./pages/tac/TAC";
import SecureDlink from "./pages/secure-D/SecureDLink";
import Feedback from "./pages/feedback/Feedback";
import QuizFeedback from "./pages/quizFeedback/Feedback";
import BuySpin from "./pages/buySpin/BuySpin";
import PlayGameFrame from "./pages/playGameFrame/PlayGameFrame";
import WaitingPage from "./pages/landingPage/WaitingPage";
const Wallet = lazy(() => import("./pages/wallet/Wallet"));
function Mobi2() {
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
      allVariants: {
        color: "#707070",
      },
    },
  });
  return (
    <div className="">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<AuthenticateRoutes />}>
        
           <Route exact path="/" element={<LandingPage />} />
           <Route exact path="/waiting" element={<WaitingPage />} />
              {/* <Route exact path="/" element={<Navigate to="/qwiz/logins" />} /> */}
            <Route exact path="/logins" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/tac" element={<TAC />} />
            <Route
              exact
              path="/direct-subscription/:code"
              element={<SecureDlink />}
            />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route exact path="/started" element={<Welcome />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/quizfeedback" element={<QuizFeedback />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/rules" element={<RulesPage />} />
            <Route exact path="/help" element={<HelpPage />} />
            <Route path="/homepage" element={<Homepage />} />
            <Route path="/refer" element={<Navigate to="/qwiz/homepage" />} />
            <Route path="/gameplay" element={<Gameplay />} />
            <Route exact path="/subscribe" element={<PaymentMethod />} />
            <Route exact path="/plans" element={<AvailablePlan />} />
            <Route exact path="/processing" element={<Pending />} />
            <Route exact path="/security" element={<Security />} />
            <Route exact path="/winnings" element={<Winning />} />
            <Route
              exact
              path="/leaderboard"
              element={<Navigate to="/qwiz/qwiz-leaderboard" />}
            />
            <Route
              exact
              path="/qwiz-leaderboard"
              element={<QwizLeaderboard />}
            />

            <Route
              exact
              path="/subscriptionActivity"
              element={<SubscriptionActivity />}
            />
            <Route
              exact
              path="/wallet"
              element={
                <Suspense fallback="loading">
                  <Wallet />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy-spin"
              element={
                <Suspense fallback="loading">
                  <BuySpin />
                </Suspense>
              }
            />
            <Route
              exact
              path="/playGame"
              element={
                <Suspense fallback="loading">
                  <PlayGameFrame />
                </Suspense>
              }
            />
          </Route>
        </Routes>
        <Chatwoot />
      </ThemeProvider>
    </div>
  );
}

export default Mobi2;

function ProtectedRoutes({ children }) {
  const auth = JSON.parse(sessionStorage.getItem("ccAuth"))?.userPassport;
  return <>{!!auth ? <Outlet /> : <Navigate to="/" />}</>;
}
function AuthenticateRoutes({ children }) {
  const auth = JSON.parse(sessionStorage.getItem("ccAuth"))?.userPassport;
  return <>{!!auth ? <Navigate to="/qwiz/homepage" /> : <Outlet />}</>;
}
