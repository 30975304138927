import { Typography } from "@mui/material";
import React from "react";

function PageTitle({ title }) {
  return (
    <Typography
      variant="h5"
      align="center"
      fontWeight={600}
      mt={{ xs: 1, lg: 2 }}
    >
      {title}
    </Typography>
  );
}

export default PageTitle;
