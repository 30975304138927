import React from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import CardLayout from "../../components/cardLayout/CardLayout";
import { Box, Typography } from "@mui/material";
import { trivia } from "../../../Data";
import PageTitle from "../../components/pageTitle/PageTitle";

function HelpPage() {
  const triviaList = trivia.how.map((data) => {
    data.content = data.text;
    if (!data.title) {
      data.title = "";
    }
    return data;
  });
  return (
    <AuthLayout>
      <ContentPagePanel title="How to play" options={triviaList} />
    </AuthLayout>
  );
}

export default HelpPage;
export function ContentPagePanel({ title, options }) {
  return (
    <>
      <PageTitle title={title} />
      <CardLayout>
        <TextList options={options} />
      </CardLayout>
    </>
  );
}
export function TextList({ options }) {
  return (
    <Box>
      {options?.map(({ sn, title, content }) => (
        <Typography variant="body1" mb={1}>
          <span style={{ fontWeight: 700 }}>{sn + ". " + title}</span>{" "}
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </Typography>
      ))}
    </Box>
  );
}
