import { useMutation } from "react-query"
import { request } from "../api/fetch"

export const useLogPlayerActivity=()=>{
    const logActivity = ({action_type, user_id}) => {
        const { data } = request.post(`/trivia/log_user_flow`, { action_type, user_id })
        return data

    }    
    const mutation = useMutation({
        mutationFn: logActivity,
        
    })
    return mutation
}