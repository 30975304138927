import React from "react";
import {Link} from "react-router-dom";
import AuthLayout from "../../../components/layout/AuthLayout";
// style
import "../subscription.css";
//img & icons
import loginImage from "../../../assets/images/login-image.png";

export default function Pending() {
 
  return (
    <AuthLayout>
     <main className="paymentCtn">
      <div className="processingImgCtn">
        <img src={loginImage} alt="loginImage" className="paymentImg" />
      </div>
      <div className="pendingCtn">
        <h1 className="pendingheading">
            Subscription Processing
        </h1>
        <div className="pendingText">
          <p> 
             Confirm Subscription SMS on phone
          </p>
          <div className="PendingLinkCtn">
          <Link to="/qwiz/homepage" className="pendingLink">Play</Link>

          </div>

        </div>
      </div>
      
    </main>
    </AuthLayout> 
  );
}
