import React from "react";
import { Box, Paper, Typography } from "@mui/material";
function Card({ children, title, ...rest }) {
  return (
    <Paper {...rest}>
      <Typography variant="h5" py={3} fontSize={14} fontWeight={700}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
}

export default Card;
