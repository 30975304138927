import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import Countdown from "react-countdown";
import useCountdown from "../../../hooks/utils/useCountdownTimer";
import dayjs from "dayjs";
function TrialCountdown({
  handleClick,
  isExpired,
  expiryDate,
  minutes,
  seconds,
  hours,
}) {
  const { countDown } = useCountdown(new Date("2024-03-20"));

  return (
    <Box bgcolor="#FCECCD" p={2} mt={{ md: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "7px",
        }}
      >
        {
          <Typography variant="body1" fontSize={12}>
            <span style={{ fontWeight: 900 }}>Trial Ending Soon</span>
            <br />
            Your one-day free trial will expire by{" "}
            <span style={{ fontWeight: 900 }}>
              {dayjs(expiryDate).format("DD/MM/YYYY H:m")}
            </span>{" "}
            Subscribe for unlimited access to fun quizzes and more features{" "}
            {/* Your one-day free trial is about to expire in{" "}
            <span style={{ fontWeight: 900 }}>{hours} hour(s).</span> Subscribe
            for unlimited access to fun quizzes and more features{" "} */}
          </Typography>
        }{" "}
        <Box sx={{ width: 200 }}>
          {/* <Typography fontSize={12}>
            {minutes}m : {seconds}s
          </Typography> */}
          <Link to="/qwiz/buy-spin">
            <Button
              onClick={handleClick}
              fullWidth
              variant="contained"
              color="error"
            >
              <Typography fontSize={10} color="white">
                {"Subscribe"}
              </Typography>
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default TrialCountdown;
