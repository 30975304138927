import React from "react";
import Card from "./Card";
import PieChart from "../../../../../components/shared/charts/PieChart";
import { useGetGamePlayStats } from "../../../../hooks/fetch";
import { useContext } from "react";
import { VersionContext } from "../../../../../context/VersionContext";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box } from "@mui/material";
ChartJS.defaults.font.size = 12;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// const options = {
//   indexAxis:"y"
// }
function BarChart({ active = 0, inActive = 0 }) {
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      // padding: 10,
      autoPadding: false,
    },
    plugins: {
      legend: {
        // position: "right",
        // onClick: () => null,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 10,
          },
        },
      },
      title: {
        display: true,
        text: "Number of Active subscribers taking part in the quiz",
      },
      datalabels: {
        color: "white",
        formatter: function (value, context) {
          let total = context.chart.data.datasets.reduce(
            (a, b) => b.data[0] + a,
            0
          );
          console.log({ context, data: context.chart.data, total });
          value = Math.round((value * 100) / total);
          return Math.round(value) + "%";
          // context.active
          // ? context.dataset.label + "\n" + value + "%"
          // :
        },
        font: {
          weight: "bold",
        },
        // formatter: Math.round
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  const labels = ["Active Players"];

  const data = {
    labels,
    datasets: [
      {
        label: "Active Subscribers",
        data: labels.map(() => active),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",

        borderRadius: 50,
        barThickness: 35,
        stack: 1,
      },
      {
        label: "InActive Subscribers",
        data: labels.map(() => inActive),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "white",

        borderRadius: 50,
        barThickness: 35,
        stack: 1,
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
      // {...props}
    />
  );
}
function Leads({ gameStats }) {
  // const { adminVersion } = useContext(VersionContext);
  // const gameStats = useGetGamePlayStats(adminVersion);

  const data = {
    labels: ["Total Players", "Total Subscribers"],
    options: {
      plugins: {
        labels: {
          render: "label",
        },
      },
    },
    datasets: [
      {
        label: "",
        data: [gameStats?.data?.totalPlayer, gameStats?.data?.totalSubscriber],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Card
      title="Active Subscribers"
      sx={{ width: "100%", maxWidth: "100%", padding: "20px" }}
    >
      {/* <PieChart data={data} /> */}
      <Box sx={{ width: "100%", maxWidth: "450px", height: "250px" }}>
        <BarChart
          active={Number(gameStats?.data?.totalPlayer) || 0}
          inActive={
            Number(
              gameStats?.data?.totalSubscriber - gameStats?.data?.totalPlayer
            ) || 0
          }
        />
      </Box>
    </Card>
  );
}

export default Leads;
