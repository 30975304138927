import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Config } from "../Config";
import { convertQuizToGame } from "./converter/convertQuizToGame";
// HEADERS
const config = {
  headers: {
    secretKey: "tzAZjWeN929",
    accessToken: "bJ_lzM1iCApLKXoNSGxSmrZXjX8",
  },
};

// FETCH PROFILE
export const useFetchProfile = (userPassport) => {
  const fetchProfile = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/user/profile/${userPassport}`
      );
      // console.log(" FETCH DATA", data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };
  // console.log("morbor", userPassport);
  const { status, data, isFetching, refetch } = useQuery(
    ["profile", userPassport],
    () => fetchProfile(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  // console.log("00h", data);
  return { status, data, isFetching, refetch };
};

// FETCH ALL GAMES
export const useFetchAllGames = (
  filterCategories,
  searchTerm,
  url,
  version
) => {
  let pageNumber = 1;
  let pageSize = 20;

  const fetchAllGames = async (userPassport) => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/${url}&version=${version}`
      );
      // console.log("GAMES DATASS", data, userPassport);
      if (version !== "glo") {
        return convertQuizToGame(data);
      }
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching } = useQuery(
    [
      "allGames",
      filterCategories.topic_id,
      filterCategories.subject_id,
      filterCategories.classroom_id,
      searchTerm,
    ],
    () => fetchAllGames(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching };
};

// FETCH ALL GAME FILTER CATEGORIES
export const useFetchFilterCategories = (category) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFilterCategories = async () => {
      setIsPending(true);
      try {
        const { data } = await axios.get(
          `${Config.url.API_URL}/partner/${category}`,
          config
        );
        // console.log(data);
        setIsPending(false);
        setData(data);
        setError(null);
      } catch (error) {
        console.log({ error: error.response || error });
        setIsPending(false);
        setError("Could not fetch the data");
      }
    };

    fetchFilterCategories();
  }, [category]);

  return { data, isPending, error };
};

// FETCH SINGLE GAME AND IFRAME URL
export const useFetchGameURL = (fetchParams) => {
  // console.log(fetchParams);

  const fetchGameURL = async () => {
    try {
      const { data } = await axios.post(
        `${Config.url.API_URL}/telco/play`,
        fetchParams,
        config
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log("hey", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["GameURL", fetchParams],
    () => fetchGameURL(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH LEADERBOARD
export const useFetchLeaderboard = (gameID) => {
  // console.log("GAME ID", gameID);
  const fetchGameURL = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/games/leaderboard/${gameID}`,
        config
      );
      // console.log("LeaderBoard123", data);
      return data;
    } catch (error) {
      console.log({ MainError: error.response || error });
    }
  };
  // fetchGameURL()
  const { status, data, isFetching, refetch } = useQuery(
    ["Leaderboard", gameID],
    () => fetchGameURL(),

    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    useEffect(() => {
      const refreshAtInterval = setInterval(() => {
        refetch();
        // console.log("Data refreshed");
      }, 120000);

      return () => clearInterval(refreshAtInterval);
    }, [])
  );
  return { status, data, isFetching, refetch };
};

// FETCH FRIENDS
export const useFetchFriends = (searchTerm, userID) => {
  // console.log(searchTerm);
  const [errorResponse, setErrorResponse] = useState({});

  const fetchFriends = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/invite?searchId=${searchTerm}&userId=${userID}`
        // config
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
      setErrorResponse(error);
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["friends", searchTerm, userID],
    () => fetchFriends(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, errorResponse, refetch };
};

// FETCH REFERRAL LINK
export const useFetchReferralLink = (userPassport, version) => {
  const [errorResponse, setErrorResponse] = useState({});

  const referralLink = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/referral-link/${userPassport}?version=${version}`
        // config
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
      setErrorResponse(error);
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["referral"],
    () => referralLink(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, errorResponse, refetch };
};

// FETCH INVITATIONS
export const useFetchInvitations = (userID) => {
  // console.log(userID);

  const fetchInvitations = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/invite/my-invites/${userID}`,
        config
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["invitations", userID],
    () => fetchInvitations(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH RECENTLY PLAYED
export const useFetchRecentlyPlayed = (userPassport) => {
  // console.log(userPassport);

  const fetchRecentlyPlayed = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/games/recently-played/${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["invitations", userPassport],
    () => fetchRecentlyPlayed(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH STREAK
export const useFetchStreak = (userPassport) => {
  // console.log(userPassport);

  const fetchStreak = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/streak?user_passport=${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["streak", userPassport],
    () => fetchStreak(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH EXPIRED SUBSCRIPTION
export const useExpiredSubscription = (userPassport) => {
  const fetchExpiredSubscription = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/check-subscription/${userPassport}`
      );
      // console.log("DATA", data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["expiredSub", userPassport],
    () => fetchExpiredSubscription(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH BADGES
export const useFetchBadges = (userPassport) => {
  // console.log(userPassport);

  const fetchBadges = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/badges/${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["badges", userPassport],
    () => fetchBadges(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH REWARDS
export const useFetchRewards = (userPassport) => {
  const fetchRewards = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/telco/my-rewards/${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["rewards", userPassport],
    () => fetchRewards(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};
