import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import Modal from "../../../components/modal/Modal";
import boyImage from "../../../../assets/handBoy.png";
function TrialSuccessCard({
  open,
  handleClose,
  title,
  subtitle,
  trialStart,
  trialEnd,
}) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box py={2}>
        <Typography variant="h3" fontSize={20} fontWeight={900} align="center">
          {title}
        </Typography>
        <Typography
          mt={1}
          variant="h4"
          fontSize={16}
          fontWeight={500}
          align="center"
        >
          {subtitle}
        </Typography>
        <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={211}>
            <Image
              src={boyImage}
              color="transparent"
              alt="boy with opened arm"
            />
          </Box>
        </Box>

        <Typography
          mt={1}
          variant="h4"
          fontSize={16}
          fontWeight={500}
          align="center"
        >
          Enjoy Unlimited access to all games for the next 24 hours
        </Typography>
        <Typography
          mt={2}
          variant="h3"
          fontSize={16}
          fontWeight={900}
          align="center"
        >
          Trial Period:
        </Typography>
        <Typography
          mt={1}
          variant="h3"
          fontSize={16}
          fontWeight={500}
          align="center"
        >
          [{trialStart}] - [{trialEnd}]
        </Typography>
      </Box>
      <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ backgroundColor: "#F29F06" }}
        >
          Continue
        </Button>
      </Box>
    </Modal>
  );
}

export default TrialSuccessCard;
