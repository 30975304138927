import { useQuery } from "react-query"
import { request } from "../api/fetch"

export const useFetchPlayGameUrl = ({id, user_id}) => {
    const fetchGameUrl = async() => {
        const { data } = await request.get(`/trivia/play_quiz?quiz_id=${id}&user_id=${user_id}`)
        return data
    }
    const query = useQuery({
        queryFn: fetchGameUrl,
        queryKey:["fetch game", id, user_id]
    })
    return query
}