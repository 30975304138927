import React, { useState } from "react";
import ordinal from "ordinal";
import Modal from "../../Mobi2.0/components/modal/Modal";

function PrizePot({
  hideBreakdown,
  totalPrize = "N100,000 to win this week!",
}) {
  const [openPrizeBreakdownModal, setOpenPrizeBreakdownModal] = useState(false);
  const togglePrizeModal = () => {
    setOpenPrizeBreakdownModal((prev) => !prev);
  };
  return (
    <div className="my-4 ">
      <h4 className="text-center"> {totalPrize}</h4>
      <button
        className="w-full px-2 py-3 mt-2 bg-[#61A641] rounded-lg pointer"
        onClick={togglePrizeModal}
      >
        <p className="text-center text-white font-semibold">
          5 Winners Share N100k
        </p>
      </button>

      {/* Modals */}
      <PrizeBreakdown
        open={openPrizeBreakdownModal}
        handleClose={togglePrizeModal}
        data={["40k", "25k", "15k", "10k", "10k"]}
      />
    </div>
  );
}

export default PrizePot;

export function PrizeBreakdown({ open, handleClose, data = [] }) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <div className="w-full p-4 grid grid-cols-2  gap-2 justify-center bg-[#61A641] h-full">
        {data.map((item, index) => (
          <div
            key={index}
            className=" rounded-md stats bg-white p-4 pl-6 text-2xl text-center"
          >
            <p className="text-[#707070]">
              {ordinal(index + 1)} -
              <span className="font-semibold text-[#61A641]  "> {item}</span>
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
}
