import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Avatar, Box, Container, Divider, Typography } from "@mui/material";
import "@fontsource/poppins";
import { Footer } from "./Layout";
import TopNav, { sidelinks } from "../../../components/TopNav/TopNav";
import styles from "./AuthLayout.module.css";
function AuthLayout({ children }) {
  const userProfile = JSON.parse(sessionStorage.getItem("ccAuth"));
  return (
    <div style={{ overflow: "hidden" }}>
      <Box disableGutters className={styles.container}>
        <Box className={styles.sidebar} pt={2}>
          {/* Profile */}
          <Box mt={5} px={3}>
            <Profile
              name={userProfile?.displayName}
              avatar={userProfile?.avatar}
            />
          </Box>
          <Box mt={2} px={2}>
            <Divider />
          </Box>
          {/* sidebar */}
          <Sidebar sidelinks={sidelinks} />
        </Box>

        <Box sx={{ flex: "0.7" }} className={styles.childrenContainer}>
          <Box className={styles.children}>
            <Box
              // position={{ xs: "fixed", md: "relative" }}
              sx={{
                width: "100%",
                top: 0,
                "@media (max-width: 700px)": {
                  backgroundColor: "rgba(255, 255, 255,1)",
                },
              }}
            >
              <TopNav showLogo={true} />
            </Box>

            <Box className={styles.childrenContent}>
              <Box className={styles.content}>{children}</Box>
            </Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
function Header() {
  return (
    <Box pt={6}>
      {/* support header */}
      <Box
        height={40}
        sx={{
          backgroundColor: "#F29F06",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          fontSize={12}
          sx={{
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          For support or enquiries, please contact: 09157504476
        </Typography>
      </Box>
    </Box>
  );
}
function Sidebar({ sidelinks }) {
  return (
    <Box className={styles.sidelinks}>
      {" "}
      <Box>{/* <Typography variant="body1">Hello</Typography> */}</Box>
      <Box mt={4}>
        {sidelinks.map((nav) => (
          <NavLink to={nav.url}>
            {({ isActive, isPending, isTransitioning }) => (
              <Box
                mb={1}
                p={1}
                pl={3}
                className={`${styles.navFlex} ${
                  isActive ? styles.activeNav : styles.idleNavLink
                }`}
              >
                <Box
                  className={` ${
                    isActive ? styles.activeNavLink : styles.idleNavLink
                  }`}
                >
                  {nav.icon}
                </Box>
                <Typography
                  variant="body1"
                  ml={3}
                  fontSize={18}
                  fontWeight={600}
                  className={` ${
                    isActive ? styles.activeNavLink : styles.idleNavLink
                  }`}
                >
                  {nav?.label}
                </Typography>
              </Box>
            )}
          </NavLink>
        ))}
      </Box>
    </Box>
  );
}
function Profile({ name, avatar }) {
  return (
    <Link to="/qwiz/profile">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" fontWeight={900} fontSize={20}>
          {" "}
          {name}
        </Typography>
        <Avatar
          src={avatar}
          alt="Avatar Image"
          sx={{ bgcolor: "#F27405", height: 48, width: 48, padding: 1 }}
        />
      </Box>
    </Link>
  );
}
export default AuthLayout;
