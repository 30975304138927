import { useState } from "react";
import { Box } from "@mui/material";
import {
  ClipLoader,
  CircleLoader as CL,
  HashLoader as CircleLoader,
} from "react-spinners";
import Modal from "../modal/Modal";
function CircleLoad({ loading, color, size = 150 }) {
  return (
    <Backdrop open={loading}>
      <Box>
        <CircleLoader
          color={color}
          loading={loading}
          // cssOverride={override}
          size={size || 150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Box>
    </Backdrop>
  );
}

export default CircleLoad;
function Backdrop(props) {
  return (
    // <Modal {...props} open={props.open}>
    <Box
      sx={{
        position: "absolute",
        top: "0",
        width: "100vw",
        height: "100%",
        bgcolor: "rgba(233,213,222,0.21)",
        overflow: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3,
      }}
    >
      {props.children}
    </Box>
    //  </Modal>
  );
}
