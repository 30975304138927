import { useState, useEffect } from 'react';

function useCountdown(targetDate) {
  const [countDown, setCountDown] = useState(calculateTimeLeft(targetDate));

 

  function calculateTimeLeft (targetDate) {
    const then = new Date(targetDate).getTime();
    const now = new Date().getTime();
    const difference = then - now;

    // Check if target date has passed
    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) ),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);
  return { countDown };
}

export default useCountdown;
