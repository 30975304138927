import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//component
import Button from "../../../components/QuizButton/Button";
import AuthLayout from "../../../components/layout/AuthLayout";
import { useSubscribe } from "../../../hooks/api/post";
import Socket from "../../../components/Socket/Socket";

import {
  useSubscriptionActivity,
  useFetchPlans,
} from "../../../hooks/api/fetch";
import { toast } from "react-toastify";
//image & icons
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ErrorIcon from "@mui/icons-material/Error";

export default function SubscriptionActivity() {
  const navigate = useNavigate();
  const [reloader, setReloader] = useState(0);
  const [active, setActive] = useState(null);
  const [activePayment, setActivePayment] = useState(null);
  const [planID, setPlanID] = useState("");
  const [channel, setChannel] = useState("airtime");
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const token = userID.token;
  const userid = userID.id;
  const usersDigit = userID.userPassport;

  const fetchAllSubscriptionActivity = useSubscriptionActivity({
    userid,
  });

  const subscribe = useSubscribe();
  const { fetchAllPlansQuery, isLoading } = useFetchPlans(token);

  const HandleActive = (buttonid) => {
    setChannel(buttonid);
    setActive(buttonid);
  };
  //Handle plan
  const HandlePaymentType = (id) => {
    setPlanID(id);
    setActivePayment(id);
  };

  // handle subscription button
  async function handleSuccess() {
    try {
      const response = await subscribe.mutateAsync({
        user_id: userID.id,
        plan_id: planID,
        payment_channel: channel,
      });
      navigate("/qwiz/processing");
    } catch (error) {
      toast.dismiss()
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  // DATE FORMAT
  function formatDate(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      // timeZoneName: 'short',
    };
    return dateTime.toLocaleDateString("en-US", options);
  }

  return (
    <AuthLayout>
      <main className="paymentCtn">
        <h1 className="paymentHeading">Subscription Activity</h1>
        {/* <div className="subscriptionAct">
          <Button
            type="submit"
            handleclick={() => HandleActive("airtime")}
            btntype={`${active === "airtime" ? "planActive" : "plan"}`}
          >
            Airtime
          </Button>
          <Button
            type="submit"
            handleclick={() => HandleActive("card")}
            btntype={`${active === "card" ? "planActive" : "smallBtnDisabled"}`}
            disabled={true}
          >
            Card (coming soon)
          </Button>
        </div> */}
        <div className="subscriptopnActivityCtn">
          {/* {isLoading ? (
            <div className="subLoadingCtn">
              <div className="subloadingstate" />
            </div>
          ) : (
            fetchAllPlansQuery?.map((plan) => (
              <div className="planBtn" key={plan.id}>
                <Button
                  type="submit"
                  handleclick={() => HandlePaymentType(plan.id)}
                  btntype={`${activePayment === plan?.id ? "active" : "main"}`}
                >
                  {plan.frequency} ₦{plan.price}
                </Button>
              </div>
            ))
          )} */}
          {/* <Button
            type="submit"
            btntype={`${subscribe.isLoading ? "disabled" : "subscribe"}`}
            handleclick={() => handleSuccess()}
            disabled={subscribe.isLoading}
          >
            {subscribe.isLoading ? (
              <div className="subLoadingCtn">
                Please wait
                <div className="subloadingstate"></div>
              </div>
            ) : (
              " Subscribe"
            )}
          </Button> */}
          <div className="subscriptionHistory">
            <h4 className="historyheading">Subscription History</h4>
          </div>
          {isLoading ?  <div className="subLoadingCtn">
              <div className="subloadingstate" />
            </div> :
          fetchAllSubscriptionActivity?.data?.length < 1 ? (
            <div className="leaderboardDataError">
              <ErrorIcon />
              You do not have any subscription activity yet{" "}
            </div>
          ) : (
            fetchAllSubscriptionActivity?.data?.map((activity) => (
              <div className="historyList" key={activity?.id}>
                <div className="PaymentInfo">
                <div className="iconContanner">
                  <ArrowOutwardIcon className="historyIcon" />
                </div>
                <div className="topUp-section">
                  <h4 className="topUp-heading">
                    Top-up
                    {""}
                    <span className="topUpSpan">
                      {formatDate(activity?.updatedAt)}
                    </span>
                    <span className="topUpStatus">{activity?.status}</span>
                  </h4>
                </div>
                </div>
                <div className="amount">{activity?.plan?.price}</div>
              </div>
            ))
          )}
        </div>
      </main>
      {/* <Socket setReloader={setReloader} usersDigit={usersDigit} /> */}
    </AuthLayout>
  );
}
