import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import classes from "./navbar.module.css";

//IMAGES
import logo from "../../../assets/Naijakids_Logo.png";
import { VersionContext } from "../../../context/VersionContext";
import { useFetchQuiz } from "../../hooks/fetch";

const Navbar = () => {
  const navigate = useNavigate();
  const {
    version,
    adminVersion,
    setAdminVersion,
    quiz,
    setQuiz,
    quizAPIData,
    setQuizAPIData,
  } = useContext(VersionContext);
  const fetchQuiz = useFetchQuiz(adminVersion);
  useEffect(() => {
    setQuizAPIData((e) => fetchQuiz?.data);
    console.log({ fetchQuiz: fetchQuiz?.data, quizAPIData });
    return () => setQuizAPIData();
  }, [fetchQuiz?.data]);
  console.log({ quizAPIData });
  function handleLogout() {
    // navigate(`/${version}/login`);
    navigate(`/admin`);
  }

  return (
    <nav className={classes.nav}>
      <div className={classes.container}>
        <div className={classes.logoWrap}>
          <div>
            <img src={logo} alt="" />
          </div>
          <div className={classes.text}>Mobi Admin</div>
        </div>

        <div className={classes.projects}>
          <div className={classes.project}>
            <label htmlFor="projects" className={classes.label}>
              {" "}
              Select Project:
            </label>
            <select
              id="projects"
              className={classes.select}
              defaultValue={adminVersion}
              onChange={(e) => setAdminVersion((a) => e?.currentTarget?.value)}
            >
              <option value="" disabled>
                Select a Project
              </option>
              <option value="">Overall</option>
              <option value="MTN">Mtn</option>
              <option value="Tosse">Tosse</option>
              <option value="glo">Glo</option>
            </select>
          </div>
          <div className={classes.project}>
            <label htmlFor="projects" className={classes.label}>
              {" "}
              Select Quiz:
            </label>
            <select
              id="projects"
              className={classes.select}
              defaultValue={adminVersion}
              onChange={(e) => {
                let label =
                  e?.currentTarget[e?.currentTarget?.selectedIndex]?.label;
                // console.log({ttt:})
                return setQuiz((quiz) => ({
                  title: label,
                  id: e?.currentTarget?.value,
                }));
              }}
            >
              <option value="" disabled>
                Select a Quiz
              </option>
              {fetchQuiz?.data?.allQuiz?.map((option, index) => (
                <option value={option?.id} key={option?.id + index}>
                  {option?.quiz_title}
                </option>
              ))}
              <option value="all">Overall</option>
            </select>
          </div>
        </div>

        <button
          className={classes.text}
          onClick={(e) => handleLogout()}
          style={{ color: "white", backgroundColor: "red" }}
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
