import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, NavLink, Link } from "react-router-dom";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";
import RedeemIcon from "@mui/icons-material/Redeem";
import GavelIcon from "@mui/icons-material/Gavel";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";

// STYLES
import classes from "./TopNav.module.css";

// IMAGES
import KidsLogo from "../../assets/9ijaKids-Logo.png";
import GloLogo from "../../assets/qwiz-logo.png";
import MtnLogo from "../../assets/qwiz-logo.png";
import astronaut from "../../assets/astronaut.png";
import { Avatar } from "../../Data";
import { useFetchProfile } from "../../hooks/fetch";
import { VersionContext } from "../../context/VersionContext";
import { Wallet } from "@mui/icons-material";
export const sidelinks = [
  {
    url: "/qwiz/homepage",
    icon: <HomeIcon />,
    label: "Home",
  },
  {
    url: "/qwiz/help",
    icon: <DescriptionIcon />,
    label: "How to Play",
  },
  {
    url: "/qwiz/rules",
    icon: <GavelIcon />,
    label: "Rules",
  },
  {
    // url: "/qwiz/plans",
    // url:"http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web",
    url:  `https://ng-app.com/VasDigimobility/9ijakidstriviaquiz-Landing-en-doi-web?origin_banner=1&trfsrc=9ijakidsweb` 
    ,
    icon: <SubscriptionsIcon />,
    label: "Subscribe",
  },
  {
    url: "/qwiz/subscriptionActivity",
    icon: <AccessTimeIcon />,
    label: "Subscription activity",
  },
  {
    url: "/qwiz/wallet",
    icon: <Wallet />,
    label: "Wallet",
  },
  {
    url: "/qwiz/qwiz-leaderboard",
    icon: <LeaderboardIcon />,
    label: "Leaderboard",
  },

  {
    url: "/qwiz/refer",
    icon: <RedeemIcon />,
    label: "Earn extra points",
  },
  {
    url: "/qwiz/winnings",
    icon: <PaymentsTwoToneIcon />,
    label: "Winnings",
  },
  {
    url: "/qwiz/security",
    icon: <LockOutlinedIcon />,
    label: "Security",
  },
  {
    url: "/qwiz/logout",
    icon: <LogoutIcon />,
    label: "Login with another number",
  },
];

export default function TopNav({ verse, showLogo = true }) {
  const { version } = useContext(VersionContext);
  const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data: profile } = useFetchProfile(userInfo?.userPassport);
  const [isAuth, setIsAuth] = useState("");
  const { pathname } = useLocation();
  const [isMenu, setIsMenu] = useState(false);
  const navigate = useNavigate();

  //FIND USER AVATAR IN AVATAR DATA
  const userAvatar = Avatar.find(
    (avatar) => avatar.title == profile?.data?.avatar
  );

  // CHECK USERS AUTH STATUS AS THEY MOVE THROUGH THE APPLICATION
  useEffect(() => {
    const auth = sessionStorage.getItem("ccAuth");
    setIsAuth(auth);
  }, [pathname]);

  const logOut = () => {
    sessionStorage.clear();
    if (version === "mtn") {
      navigate(`/${version}`);
    } else {
      navigate(`/${version}/login`);
    }
  };

  return (
    <>
      <nav className={classes.TopNav}>
        <ul>
          {!isAuth && (
            <>
              <li>
                <img src={KidsLogo} alt="9ijakids logo" />
              </li>

              <li>
                {showLogo && (
                  <img
                    src={version === "mtn" ? MtnLogo : GloLogo}
                    alt={version === "mtn" ? "MtnLogo" : "Glo logo"}
                    style={{ height: "46px" }}
                  />
                )}
                {/* <img src={GloLogo} alt="Glo logo" /> */}
              </li>
            </>
          )}

          {isAuth && (
            <Link to="/qwiz/profile">
              <div className={classes.userProfile}>
                {profile?.data?.userInfo?.display_name === null ? (
                  <span> Hello </span>
                ) : (
                  <span> {userInfo?.displayName} </span>
                )}
                <img
                  src={userInfo?.avatar}
                  alt={
                    profile?.data?.userInfo?.avatar
                      ? profile?.data?.userInfo?.avatar
                      : "Profile"
                  }
                  className={classes.avatar}
                />
              </div>
            </Link>
          )}

          <li>
            <div className={classes.burger}>
              <div onClick={() => setIsMenu(!isMenu)}>
                <span></span>
                <span></span>
                <span></span>
              </div>

              {isMenu && (
                <div className={classes.burgerMenu}>
                  {version === "mtn" && (
                    <>
                      <div onClick={() => navigate(`/${version}/tac`)}>TAC</div>
                      <div onClick={() => navigate(`/${version}/rules`)}>
                        Rules
                      </div>
                      <div onClick={() => navigate(`/${version}/help`)}>
                        How To Play
                      </div>
                    </>
                  )}
                  {/* <div onClick={() => navigate(`/${version}/faq`)}>FAQs</div> */}
                  {/* <NavLink to="/qwiz/homepage">
                    {({ isActive, isPending, isTransitioning }) => (
                      <div
                        className={`${classes.navFlex} ${
                          isActive ? classes.activeNavLink : classes.idleNavLink
                        }`}
                      >
                        <div className="">
                          <HomeIcon />
                        </div>
                        <div>Home</div>
                      </div>
                    )}
                  </NavLink> */}
                  {sidelinks.map((nav) => (
                    <NavLink to={nav.url}>
                      {({ isActive, isPending, isTransitioning }) => (
                        <div
                          className={`${classes.navFlex} ${
                            isActive
                              ? classes.activeNavLink
                              : classes.idleNavLink
                          }`}
                        >
                          <div className="">{nav.icon}</div>
                          <div>{nav?.label}</div>
                        </div>
                      )}
                    </NavLink>
                  ))}

                  {/* <div>
                    <a
                      href="https://wa.me/message/GBEFBYHAWUIRH1"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </div>
                  {isAuth ? (
                    <div onClick={() => logOut()}>Logout</div>
                  ) : (
                    <div onClick={() => navigate(`/${version}/login`)}>
                      Login
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </li>
        </ul>
      </nav>

      <div className={classes.banner}>
        <span>
          {" "}
          For support or enquiries, please contact:{" "}
          <a
            href="https://wa.me/message/GBEFBYHAWUIRH1"
            target="_blank"
            className={classes.link}
          >
            09157504476
          </a>
        </span>
      </div>
    </>
  );
}
