import React from "react";
import Image from "material-ui-image";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Modal from "../../components/modal/Modal";

function Winner({
  open,
  handleClose,
  title = "Congatulation",
  titleIcon,
  content,
  image,
  buttonColor,
  buttonText,
  buttonIcon,
  buttonFunction,
  buttonVariant,
}) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <Stack mb={3} direction="row">
          <Typography variant="h5" align="center" sx={{ width: "100%" }}>
            {title}
          </Typography>
          <Box>
            <Image src={titleIcon} />
          </Box>
        </Stack>
        <Box my={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 126, height: 126 }}>
            <Image src={image} />
          </Box>
        </Box>
        <Typography variant="body1" align="center">
          {content}
        </Typography>
        <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color={buttonColor}
            startIcon={buttonIcon}
            onClick={buttonFunction}
            variant={buttonVariant}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default Winner;
