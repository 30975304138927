import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { useNavigate, Link } from "react-router-dom";
import HowToPlay from "./HowToPlay";
import Image from "material-ui-image";
import { Paper, Button, Typography, Box, Stack, Skeleton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import { howToPlay } from "../../data/data";
import giftImage from "../../../assets/gift-box.png";
import giftImage2 from "../../../assets/gift-2.png";
import boyImage from "../../../assets/start-game-image.png";

import AuthLayout from "../../components/layout/AuthLayout";
import {
  useCheckSubscribeStatus,
  useFetchAllQuiz,
  useFetchProfile,
  useFetchRewards,
  useFetchTrialStatus,
} from "../../hooks/api/fetch";
import NoSubscription from "./NoSubscription";
import Winner from "./Winner";
import {
  useAcknowledger,
  useAcknowledgeReward,
  useAcknowledgeTrial,
  useUpdateProfile,
} from "../../hooks/api/post";
import TrialCard from "./components/TrialCard";
import TrialSuccessCard from "./components/TrialSuccessCard";
import TrialCountdown from "./components/TrialCountdown";
import useCountdown from "../../hooks/utils/useCountdownTimer";
import UpdateEmail from "./components/UpdateEmail";
import { toast } from "react-toastify";
import Resources from "./features/Resources";
import PrizePot from "../../../components/prizePot/PrizePot";
import EarnPointBanner from "./components/EarnPointBanner";
function Homepage() {
  const navigate = useNavigate();
  const allQuizApi = useFetchAllQuiz();
  useEffect(() => {
    if (allQuizApi.data) {
      sessionStorage.setItem("quizID", allQuizApi.data?.quizzes?.[0]?.id);
    }
  }, [allQuizApi.data]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("ccAuth"))
  );

  const rewardsAPI = useFetchRewards(userInfo?.id);
  const acknowledgeRewardAPI = useAcknowledgeReward();
  const subscriptionState = useCheckSubscribeStatus(userInfo?.userPassport);
  const [activeQuiz, setActiveQuiz] = useState(false);
  useEffect(() => {
    if (subscriptionState.data?.profile_notification) {
      navigate("/qwiz/profile?status=update");
    }
    if (
      subscriptionState.data?.message === "Subscription available!" &&
      subscriptionState.data?.subscription?.plays > 0
    ) {
      setActiveQuiz(true);
    } else {
      setActiveQuiz(false);
    }
    return () => setActiveQuiz((active) => !active);
  }, [subscriptionState.data]);
  const [openHowToPlayModal, setOpenHowToPlayModal] = useState(
    localStorage.getItem("hideHelp") === "true" ? false : true
  );

  const [openNoSubModal, setOpenNoSubModal] = useState(
    localStorage.getItem("newbie") === "yes" ? true : false
  );
  const [quizInfo, setQuizInfo] = useState();
  const [gamePlayModal, setGamePlayModal] = useState(false);
  const fetchProfile = useFetchProfile(userInfo?.id);
  function handleGameCardClick(id, quiz) {
    if (!activeQuiz) {
      setOpenNoSubModal(true);
    } else {
      // navigate(`/qwiz/gameplay?id=${id}`);
      if (localStorage.getItem("hideHelp") !== "true") {
        setOpenHowToPlayModal(true);
      }
      sessionStorage.setItem("quizID", id);
      setQuizInfo((quizes) => ({
        title: quiz?.quiz_title,
        id: quiz?.id,
        summary: quiz?.quiz_description,
        time: quiz?.quiz_duration,
        questionCount: quiz?.quiz_question_number,
        quizType: quiz?.quizType,
      }));
      setGamePlayModal(true);
    }
  }

  function handleCloseHowToPlayModal() {
    setOpenHowToPlayModal(false);
  }
  function handleCloseNoSubModal() {
    setOpenNoSubModal(false);
    localStorage.setItem("newbie", "no");
  }

  const [openWinningModal, setOpenWinningModal] = useState(false);
  useEffect(() => {
    const winnings = rewardsAPI?.data?.winning;
    if (winnings?.length > 0 && winnings?.[0]?.isSeen === 0) {
      setOpenWinningModal(true);
    }
  }, [rewardsAPI?.data?.winning]);
  function handleCloseWinningModal() {
    acknowledgeRewardAPI.mutate({ user_id: userInfo.id });
    setOpenWinningModal((modal) => !modal);
  }

  const [trialSuccess, setTrialSuccess] = useState(false);
  const acknowledgeTrialSuccess = useAcknowledgeTrial();
  function closeTrialModal() {
    setTrialSuccess(false);
    acknowledgeTrialSuccess.mutate({
      phoneNumber: userInfo?.userPassport,
    });
  }
  const fetchTrialStatus = useFetchTrialStatus({
    phone_number: userInfo?.userPassport,
  });
  const { countDown } = useCountdown(
    new Date(fetchTrialStatus.data?.subscription_end)
  );
  console.log({ fetchTrialStatus, countDown });

  useEffect(() => {
    let data = fetchTrialStatus.data;
    if (data) {
      setTrialSuccess(
        data?.subscription?.tier_congratulation_status === "opened"
          ? true
          : false
      );

      if (
        data?.hasFeedback === false &&
        data?.subscription_elapse === "00:00:00" &&
        data?.subscription?.is_free_trier === 1
      ) {
        navigate("/qwiz/feedback");
      }
    }
  }, [fetchTrialStatus.data]);

  function convertDate(date) {
    let newDate = new Date(date);
    return `${newDate.getDate()}/${newDate.getMonth() + 1} ${newDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${newDate.getMinutes().toString().padStart(2, "0")}`;
  }

  const [showUpdateEmail, setShowUpdateEmail] = useState(false);

  useEffect(() => {
    // Api to let us know when to update profile
    if (false) {
      if (!!fetchProfile.data?.user?.email) {
        setShowUpdateEmail(false);
      } else {
        setShowUpdateEmail(true);
      }
    }
  }, [fetchProfile.data]);

  const [newEmail, setNewEmail] = useState("");
  const updateProfileApi = useUpdateProfile();
  function hideUpdateEmailModal() {
    updateProfileApi.mutate(
      {
        userId: userInfo.id,
        data: {
          name: fetchProfile.data.user.name,
          email: newEmail,
          display_name: fetchProfile.data.user.display_name,
          avatar: fetchProfile.data.user.avatar,
          first_login: false,
        },
      },
      {
        onSuccess: () => {
          toast.dismiss();
          toast.success("Avatar updated successfully");
          // sessionStorage.setItem(
          //   "ccAuth",
          //   JSON.stringify({ ...userProfile, avatar: newAvatar })
          // );
          // handleCloseAvatarModal();
        },
        onError: (err) => {
          toast.error("Avatar update failed");
        },
      }
    );
    setShowUpdateEmail(false);
  }
  return (
    <AuthLayout data-testid="authLayout">
      {((fetchTrialStatus.data?.subscription?.is_free_trier === 1 &&
        fetchTrialStatus.data?.subscription_elapse === "00:00:00") ||
        fetchTrialStatus.data?.subscription?.tier_status === "opened") && (
        <TrialCard
          isExpired={
            fetchTrialStatus.data?.subscription?.is_free_trier === 1 &&
            fetchTrialStatus.data?.subscription_elapse === "00:00:00"
              ? true
              : false
          }
          expiryDate={fetchTrialStatus.data?.subscription_end}
          // expiryDate={fetchTrialStatus.data?.subscription_status}
        />
      )}
      {fetchTrialStatus.data?.subscription?.is_free_trier === 1 &&
        fetchTrialStatus.data?.subscription_elapse !== "00:00:00" && (
          <TrialCountdown
            expiryDate={fetchTrialStatus.data?.subscription_end}
            minutes={countDown.minutes}
            seconds={countDown.seconds}
            hours={countDown.hours}
          />
        )}
      <PrizePot />
      <Resources />

        {/* <EarnPointBanner /> */}
      <Box
        sx={{
          padding: "15px",
          minHeight: "calc(100vh - 220px)",
          backgroundColor: "#F8F8F8",
          marginTop: "40px",
          borderTopLeftRadius: "40px",
          borderTopRightRadius: "40px",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={900}
          fontSize={16}
          mb={2}
          // onClick={() => setActiveQuiz((act) => !act)}
        >
          {" "}
          Current Quiz
        </Typography>
        {allQuizApi?.isLoading && <SkeletonCard />}
        {allQuizApi?.data?.quizzes?.map((quiz) => (
          <GameCard
            key={quiz.id}
            id={quiz.id}
            title={quiz?.quiz_title}
            summary={quiz?.quiz_description}
            icon={giftImage}
            time={quiz?.quiz_duration}
            onClick={() => handleGameCardClick(quiz?.id, quiz)}
            status={activeQuiz}
            questionCount={quiz?.quiz_question_number}
            quizType={quiz?.quizType}
          />
        ))}
        {/* <GameCard
          title="Spelling Buzz QUiz"
          summary="Test your spelling skills in our Spelling Bee Quiz. Compete to become the ultimate word champion!"
          icon={giftImage}
          time="3"
          onClick={() => navigate("/qwiz/gameplay")}
        /> */}

        {/* Modals */}
        <NoSubscription
          title="No Plays left"
          open={openNoSubModal}
          handleClose={handleCloseNoSubModal}
        />

        <TrialSuccessCard
          title="Congratulations"
          subtitle=" Your one-day free trial has been successfully activated."
          open={trialSuccess}
          handleClose={closeTrialModal}
          trialStart={
            convertDate(fetchTrialStatus.data?.subscription_start)
            // ?.split("T")
            // ?.join(" ")
            // ?.replace("2024-", "")
            // ?.replace(".000Z", "")
            // ?.replaceAll("-", "/")
          }
          trialEnd={
            convertDate(fetchTrialStatus.data?.subscription_end)
            // ?.replace("2024-", "")
            // ?.replaceAll("-", "/")
          }
        />

        <GamePlayModal
          open={gamePlayModal}
          handleClose={() => setGamePlayModal(false)}
          quiz_type={quizInfo?.quizType}
          key={quizInfo?.id}
          id={quizInfo?.id}
          title={quizInfo?.title}
          summary={quizInfo?.summary}
          time={quizInfo?.time}
          questionCount={quizInfo?.questionCount}
        />
        <HowToPlay
          options={howToPlay}
          open={openHowToPlayModal}
          handleClose={handleCloseHowToPlayModal}
        />
        <Winner
          open={openWinningModal}
          title="Congratulations 🎉"
          content="You have won ₦5, 000 and payment will made to account ending 5738 within 24 hours"
          image={giftImage2}
          buttonText="Back to Home"
          buttonColor="error"
          buttonVariant="contained"
          buttonIcon={<HomeIcon />}
          buttonFunction={handleCloseWinningModal}
          handleClose={handleCloseWinningModal}
        />
      </Box>
      {showUpdateEmail && (
        <UpdateEmail
          handleClose={hideUpdateEmailModal}
          setNewEmail={setNewEmail}
        />
      )}{" "}
    </AuthLayout>
  );
}

export default Homepage;
export function GameCard({
  key,
  title,
  icon,
  summary,
  time,
  status,
  onClick,
  questionCount,
}) {
  return (
    <Paper
      key={key}
      sx={{
        position: "relative",
        borderTopRightRadius: "15px",
        cursor: "pointer",
        mb: 2,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          background: status ? "#61A641" : "#F24444",
          borderTopRightRadius: "15px",
        }}
      >
        <Typography
          variant="body2"
          fontSize={14}
          sx={{ color: "white", padding: "11px" }}
        >
          {status ? "Unlocked" : "Locked"}
        </Typography>
      </Box>
      {!status && (
        <Box
          sx={{
            position: "absolute",
            top: "60px",
            right: "20px",
          }}
        >
          <LockIcon color="error" />
        </Box>
      )}
      <Stack direction="row" alignItems={"center"} gap={2} p={1}>
        <Box height={72} width={72}>
          <Image src={icon} color="transparent" />
        </Box>
        <Box sx={{ maxWidth: "202px" }}>
          <Typography variant="h6" fontSize={14} fontWeight={600} mt={1} mb={1}>
            {title}
          </Typography>
          <Typography variant="body1" fontSize={10} fontWeight={300} mb={1}>
            {summary}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AccessTimeIcon />
            <Typography variant="body1" fontSize={10} ml={1}>
              {time} mins
            </Typography>
            <Typography variant="body1" fontSize={10} ml={1}>
              / {questionCount} questions
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}
function SkeletonCard() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  );
}

function GamePlayModal({
  open,
  handleClose,
  title,
  id,
  summary,
  time,
  questionCount,
  quiz_type,
}) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box p={2}>
        <Typography
          mt={1}
          variant="h3"
          fontSize={20}
          fontWeight={900}
          align="center"
        >
          {title}
        </Typography>
        <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={100}>
            <Image
              aspectRatio={9 / 16}
              src={boyImage}
              color="transparent"
              alt="boy with opened arm"
            />
          </Box>
        </Box>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={900}
          align="center"
        >
          {summary}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccessTimeIcon />
          <Typography
            ml={1}
            variant="body2"
            fontSize={12}
            fontWeight={900}
            align="center"
          >
            {time}mins
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            fontSize={12}
            fontWeight={900}
            align="center"
          >
            / {questionCount} questions
          </Typography>
        </Box>
        <Box
          my={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to={
              quiz_type === "game"
                ? `/qwiz/playgame?id=${id}`
                : `/qwiz/gameplay?id=${id}`
            }
          >
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: "#F29F06" }}
            >
              {quiz_type} Play
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
  );
}
