import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import Image from "material-ui-image";
import * as Yup from "yup";
import Layout from "../../components/layout/Layout";
import sadBoyImage from "../../assets/images/boy-sad.png";
import stopBoyImage from "../../assets/images/boy-stop.png";
import boyIcon from "../../../assets/pointing-bubbles.png";
import {
  Box,
  Button,
  Container,
  Checkbox,
  Typography,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useLogin } from "../../hooks/api/post";
import { useNavigate } from "react-router-dom";
import PrizePot from "../../../components/prizePot/PrizePot";
import { useLogPlayerActivity } from "../../hooks/logs/useLogger";
function Login() {
  const navigate = useNavigate();
  const loginAPI = useLogin();
  const loginSchema = Yup.object().shape({
    user_passport: Yup.string()
      .required("Mobile number is required")
      .matches(
        /^(234)\d{10}$/,
        "Mobile number must be a 13 digits number that starts with 234"
      ),
  });
  const [loginResponse, setLoginResponse] = useState("");
  const logActivity = useLogPlayerActivity();

  useEffect(() => {
    logActivity.mutate({
      action_type: "Get Started",
    });
    const user_passport = localStorage.getItem("user_passport");
    const rememberMe = localStorage.getItem("rememberMe");
    if (!!user_passport && rememberMe) {
      console.log("autologin");
      loginAPI.mutate(
        { user_passport: user_passport, service_id: 1 },
        {
          onSuccess: (res) => {
            toast.dismiss();
            toast.success("Login Successful");
            // console.log({ femmmmi: res, number });
            const userInfo = JSON.stringify({
              token: res?.token,
              userPassport: user_passport,
              id: res?.user?.id,
              displayName: res?.user?.display_name,
              name: res?.user?.name,
              ageRange: res?.user?.age_range,
              avatar: res?.user?.avatar,
            });

            if (res.user.display_name === "null") {
              sessionStorage.setItem("closeCreate", false);
            }
            sessionStorage.setItem(
              "quizID",
              "24df12d9-1116-4b57-a458-c2e39d6fd36a"
            );
            sessionStorage.setItem("ccAuth", userInfo);
            sessionStorage.setItem("displayName", res?.user?.display_name);
            if (res?.user?.first_login === 0) {
              sessionStorage.setItem("newbie", "no");
            } else {
              sessionStorage.setItem("newbie", "yes");
              localStorage.setItem("hideHelp", "false");
            }
            res?.user?.first_login === 1
              ? navigate("../started")
              : navigate("../homepage");
          },
          onError: (err) => {
            toast.dismiss();
            toast.error(err.response.data?.message);
            if (
              err.response.data?.message === "Number is not in correct format"
            ) {
              setLoginResponse("Incorrect Format");
            }
            if (
              err.response.data?.message === "You don't have active subscripton"
            ) {
              setLoginResponse("No Subscription");
           
              window.location.href =
              `https://ng-app.com/VasDigimobility/9ijakidstriviaquiz-Landing-en-doi-web?origin_banner=1&trfsrc=9ijakidsweb`
              // "http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web";
            }
            // navigate("../register");

            //new implementation
            // navigate("../started");
          },
        }
      );
    }
  }, []);

  return (
    <Layout>
      <Container>
        <PrizePot />
        <Box
          mt={{
            xs: 1,
            md: 3,
          }}
          mb={{
            lg: 5,
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            // "@media (min-width: 960px)": {
            //   backgroundColor: "red",
            // },
          }}
        >
          <Box
            sx={{
              width: "100%",
              "@media (min-width: 960px)": {
                // minWidth: "370px",
              },
            }}
          >
            <Image
              src={
                loginResponse === "Incorrect Format"
                  ? sadBoyImage
                  : loginResponse === "No Subscription"
                  ? stopBoyImage
                  : boyIcon
              }
              color="transparent"
              data-testid="loginImage"
            />
          </Box>
        </Box>
        <p className="font-bold text-lg text-[#707070] text-center">
          {loginResponse === "Incorrect Format"
            ? "Retry with MTN number"
            : loginResponse === "No Subscription"
            ? " Active MTN Subscription required to Play"
            : ""}
        </p>
        <Formik
          initialValues={{
            user_passport: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting }) => {
            localStorage.setItem("user_passport", values.user_passport);
            loginAPI.mutate(
              { user_passport: values.user_passport, service_id: 1 },
              {
                onSuccess: (res) => {
                  toast.dismiss();
                  toast.success("Login Successful");
                  // console.log({ femmmmi: res, number });
                  const userInfo = JSON.stringify({
                    token: res?.token,
                    userPassport: values.user_passport,
                    id: res?.user?.id,
                    displayName: res?.user?.display_name,
                    name: res?.user?.name,
                    ageRange: res?.user?.age_range,
                    avatar: res?.user?.avatar,
                  });

                  if (res.user.display_name === "null") {
                    sessionStorage.setItem("closeCreate", false);
                  }
                  sessionStorage.setItem(
                    "quizID",
                    "24df12d9-1116-4b57-a458-c2e39d6fd36a"
                  );
                  sessionStorage.setItem("ccAuth", userInfo);
                  sessionStorage.setItem(
                    "displayName",
                    res?.user?.display_name
                  );
                  if (res?.user?.first_login === 0) {
                    sessionStorage.setItem("newbie", "no");
                  } else {
                    sessionStorage.setItem("newbie", "yes");
                    localStorage.setItem("hideHelp", "false");
                  }
                  res?.user?.first_login === 1
                    ? navigate("../started")
                    : navigate("../homepage");
                },
                onError: (err) => {
                  toast.dismiss();
                  toast.error(err.response.data?.message);
                  if (
                    err.response.data?.message ===
                    "Number is not in correct format"
                  ) {
                    setLoginResponse("Incorrect Format");
                  }
                  if (
                    err.response.data?.message ===
                    "You don't have active subscripton"
                  ) {
                    setLoginResponse("No Subscription");
                    localStorage.setItem(
                      "rememberMe",
                     true
                    )
                    window.location.href =
                    `https://ng-app.com/VasDigimobility/9ijakidstriviaquiz-Landing-en-doi-web?origin_banner=1&trfsrc=9ijakidsweb` 
                    // "http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web";
                  }
                  // navigate("../register");

                  //new implementation
                  // navigate("../started");
                },
              }
            );
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Box mt={1}>
                <TextField
                  data-testid="userLogin"
                  fullWidth
                  placeholder="Enter your phone number to get started"
                  sx={{ backgroundColor: "white", minWidth: 350 }}
                  onChange={(e) =>
                    setFieldValue("user_passport", e.target.value)
                  }
                />
                <Box sx={{ color: "error.main" }}>
                  <ErrorMessage name="user_passport" />
                </Box>
                <Box
                  my={2}
                  mt={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    mr={2}
                    onChange={(e) =>
                      localStorage.setItem(
                        "rememberMe",
                        e.currentTarget.checked
                      )
                    }
                  />
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    fontSize={16}
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: "#707070",
                    }}
                  >
                    Remember me
                  </Typography>
                </Box>
              </Box>

              <Box mt={1}>
                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  sx={{
                    height: "40px",
                    background: "#F24444",
                    borderRadius: "8px",
                  }}
                  type="submit"
                  disabled={loginAPI?.isLoading}
                  data-testid="loginBtn"
                >
                  {loginAPI?.isLoading ? "Logging in ..." : " Proceed"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {/* <Box
          mt={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <Typography variant="body2"> New User? </Typography>
          <Link to="../register" style={{ color: "#61A641" }}>
            Sign Up{" "}
          </Link>
        </Box> */}
      </Container>
    </Layout>
  );
}

export default Login;
