import React, { useState } from "react";
import Button from "../../components/QuizButton/Button";
import AuthCode from "react-auth-code-input";

export default function ChangePIN({
  setPinText,
  EnterNewPinText,
  next,
  confirmNewPinText,
  createNewPinText,
  handleChangePin,
  setPinUpdate,
  updatePin
}) {
  const [nextPages, setNextPages] = useState(false);
  const [openConfirmPages, setOpenConfirmPages] = useState(false);

  const handlePinChange = (word, state) => {
    setPinUpdate((pin) => ({ ...pin, [state]: word }));
  };

  return (
    <>
      {openConfirmPages ? (
        <div className="paymentAmt" key="confirmPin">
          <h3 className="pinSettingsheading">{confirmNewPinText}</h3>
          <AuthCode
            allowedCharacters="numeric"
            length="4"
            inputClassName="setPinForm"
            placeholder="*"
            onChange={(word) => handlePinChange(word, "confirmChangedPin")}
          />

          <Button
            type="submit"
            btntype={`${
              updatePin.isLoading ? "disabled" : "active"
              
            }`}
            disabled={updatePin.isLoading}
            handleclick={() => handleChangePin()}
          >
            {updatePin.isLoading ? (
                    <div className="subLoadingCtn">
                      Please wait
                      <div className="subloadingstate"></div>
                    </div>
                  ) : (
                    createNewPinText
                  )}
          </Button>
        </div>
      ) : nextPages == false ? (
        <div className="paymentAmt" key="setPin">
          <h3 className="pinSettingsheading">{setPinText}</h3>
          <div>
            <AuthCode
              allowedCharacters="numeric"
              length="4"
              inputClassName="setPinForm"
              placeholder="*"
              onChange={(word) => handlePinChange(word, "currentPin")}
            />
          </div>

          <Button
            type="submit"
            btntype="active"
            handleclick={() => setNextPages(true)}
          >
            {next}
          </Button>
        </div>
      ) : (
        <div className="paymentAmt" key="newPin">
          <h3 className="pinSettingsheading">{EnterNewPinText}</h3>
          <AuthCode
            allowedCharacters="numeric"
            length="4"
            inputClassName="setPinForm"
            placeholder="*"
            onChange={(word) => handlePinChange(word, "newPin")}
          />

          <Button
            type="submit"
            btntype="active"
            handleclick={() => {
              setOpenConfirmPages(true);
              setNextPages(false);
            }}
          >
            {next}
          </Button>
        </div>
      )}
    </>
  );
}
