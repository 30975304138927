import React from "react";

// STYLES
import classes from "../login/Login.module.css";

// COMPONENTS
import TopNav from "../../components/TopNav/TopNav";
import AuthPages from "../../components/authPages/authPages";

// IMAGES
import Image from "../../assets/no-sub.png";

export default function NoSubscription() {
  return (
    <div className={classes.login}>
      <TopNav />
      <AuthPages
        image={Image}
        text="You Do Not Have An Active Subscription"
        login={false}
      />
    </div>
  );
}
