import React, { useState } from "react";
import { useNavigate} from "react-router-dom";
import { useSubscribe } from "../../../hooks/api/post";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// style
import "../subscription.css";
//COMPONENT
import Button from "../../../components/QuizButton/Button";
import AuthLayout from "../../../components/layout/AuthLayout";
//img & icons
import loginImage from "../../../assets/images/login-image.png";
import successImage from "../../../assets/images/pointing-bubbles.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



export default function PaymentMethod({setShowSubscribersScreen}) {
  const getUserID= sessionStorage.getItem("ccAuth")
  const userID = JSON.parse(getUserID)
  const [channel, setChannel]=useState('airtime')
  const [activePayment, setActivePayment] = useState(null);
  const navigate = useNavigate();
  const subscribe = useSubscribe()

 
  // const handleSuccess = async() => {
    async function handleSuccess(){
    try{
      const response = await subscribe.mutateAsync({
        user_id:userID.id,
        plan_id:7,
        payment_channel:channel
      });
      navigate("/qwiz/processing")
    }catch(error){
      toast.dismiss()
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    }
      
  };
  const HandleActive = (buttonid) => {
    setActivePayment(buttonid);
    setChannel(buttonid);
  };

  return (
    // <AuthLayout>
    <main className="paymentCtn">
      <button onClick={()=>setShowSubscribersScreen(false)} className="paymentBtnctn">
        <ArrowBackIcon/></button>
      <h1 className="paymentHeading" >Select a payment method</h1>
      <div className="paymentImgCtn">
        <img src={loginImage} alt="loginImage" className="paymentImg" />
      </div>
      <div className="paymentType">
        <Button
          type="submit"
          handleclick={() => HandleActive("airtime")}
            btntype={`${activePayment === "airtime" ? "active" : "main"}`}
        >
          Airtime
        </Button>
        <Button
          type="submit"
          btntype="disabled"
          handleclick={()=>setChannel("card")}
          disabled={true}
        >
          Pay with card (coming soon)
        </Button>
        <Button
          type="submit"
          btntype={`${subscribe.isLoading ? "disabled" : "subscribe"}`}
          handleclick={()=>handleSuccess()}
          disabled={subscribe.isLoading}
        >
          {subscribe.isLoading ? (
                  <div className="subLoadingCtn">
                    Please wait
                    <div className="subloadingstate"></div>
                  </div>
                ) : (
                  " Subscribe"
                )}
        </Button>
      </div>
    </main>
    // </AuthLayout>
  );
}
