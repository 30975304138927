export const howToPlay = [
  {
    title: "Subscribe to Unlock:",
    text: " Before you begin, make sure to subscribe to gain access to a wide range of quizzes.",
  },
  {
    title: "Select a quiz:",
    text: " Choose a quiz from the list of available quizzes. ",
  },
  {
    title: "Start the Quiz:",
    text: `  Tap on the "Start Quiz" button to begin the quiz.`,
  },
  {
    title: "Answer the Questions:",
    text: ` Read each question carefully and select your answer from the options provided.        `,
  },
  {
    title: "Earn Points:",
    text: ` Score points for each correct answer.
        `,
  },
  {
    title: "Check Your Progress:",
    text: ` Keep an eye on the progress bar to see how far you've come.
        `,
  },
  {
    title: "View Leaderboard:",
    text: ` After answering all the questions, view your results and see how you performed.`,
  },
];
