import React, { useState } from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import { AccountBalanceWallet, ArrowForwardIos } from "@mui/icons-material";
import useBuySpinLogic from "./useBuySpinLogic";
import useWalletLogic from "../wallet/useWalletLogic";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
function BuySpin() {
  const [paymentProps, setPaymentProps] = useState({
    stage: "selectPayment",
  });
  return (
    <AuthLayout>
      {paymentProps.stage === "selectPayment" ? (
        <SelectPayment setPaymentProps={setPaymentProps} />
      ) : (
        <WalletPayment />
      )}
    </AuthLayout>
  );
}

export default BuySpin;

function SelectPayment({ setPaymentProps }) {
  const { subscriptionStatus } = useBuySpinLogic();
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className=" max-w-lg w-full relative sm:pb-8 lg:pt-8">
        <div className="white-card rounded-md bg-white wrapper rounded-t-2xl">
          <div className="px-4 sm:px-8 py-8 sm:py-8">
            <h1 className="mb-8 sm:mb-12 font-bold text-xl text-center">
              Buy Play
            </h1>
            <div className="bg-crimson bg-[#f29f0675] bg-opacity-10 rounded-xl px-4 py-4 flex justify-between items-center">
              <div>
                <h3 className="text-sm uppercase text-black-grey font-bold">
                  Play Balance
                </h3>
                <h4 className="text-sm mt-2 font-normal">
                  <span className=" text-2xl font-bold">
                    {subscriptionStatus.data?.subscription?.plays}
                    {/* Lives:{subscriptionStatus.data?.subscription?.lives} */}
                  </span>
                </h4>
              </div>
              <div className="w-16 h-16 rounded-full flex justify-center items-center bg-[#f27405] text-white">
                <AccountBalanceWallet />
              </div>
            </div>
            <div className="mt-8">
              <h3 className="text-sm font-semibold mb-2 text-black-neutral">
                Buy Plays
              </h3>
              {/* <div className="cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border">
            <p className="text-black text-[17px]">Bank Account</p>
            <ArrowForwardIos />
          </div> */}
              <button
                onClick={() =>
                  setPaymentProps((prev) => ({
                    ...prev,
                    stage: "walletPayment",
                  }))
                }
                className="btn mb-4 cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
              >
                Wallet
                <ArrowForwardIos sx={{ fontSize: 17 }} />
              </button>
              {/* <Link
                to={`http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web?trxId=`}
              > */}
              <button
                disabled
                className="btn cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
              >
                Airtime
                {/* <div>
              <p className=" ">Airtime</p>
            </div> */}
                <ArrowForwardIos sx={{ fontSize: 17 }} />
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WalletPayment({ setPaymentProps }) {
  const navigate = useNavigate();
  const { handleBuyPlay } = useBuySpinLogic();
  const { walletBalance } = useWalletLogic();
  console.log({ walletBalance: walletBalance?.error?.response?.data?.message });
  if (walletBalance?.error?.response?.data?.message === "Wallet not found") {
    toast.dismiss();
    toast.warning(`Set up a 4 digit pin to access your wallet`);
    navigate(`/qwiz/security`);
  }
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className=" max-w-lg w-full relative sm:pb-8 lg:pt-8">
        <div className="white-card rounded-md bg-white wrapper rounded-t-2xl">
          <div className="px-4 sm:px-8 py-8 sm:py-8">
            <h1 className="mb-8 sm:mb-12 font-bold text-xl text-center">
              Wallet Payment
            </h1>
            <div className="bg-crimson bg-[#f29f0675] bg-opacity-10 rounded-xl px-4 py-4 flex justify-between items-center">
              <div>
                <h3 className="text-sm uppercase text-black-grey font-medium">
                  Wallet Balance
                </h3>
                <h4 className="text-2xl font-bold">
                  ₦{walletBalance.data?.wallet?.balance}
                </h4>
              </div>
              <div className="w-16 h-16 rounded-full flex justify-center items-center bg-[#f27405] text-white">
                <AccountBalanceWallet />
              </div>
            </div>
            <div className="mt-8">
              <h3 className="text-sm font-semibold mb-2 text-black-neutral">
                Buy Plays
              </h3>
              {/* <div className="cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border">
            <p className="text-black text-[17px]">Bank Account</p>
            <ArrowForwardIos />
          </div> */}
              <div className=" flex flex-col ">
                <button
                  onClick={() => handleBuyPlay("plays", 1)}
                  className="btn mb-4 cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
                >
                  1 Play - 50
                  <ArrowForwardIos sx={{ fontSize: 17 }} />
                </button>
                <button
                  onClick={() => handleBuyPlay("plays", 2)}
                  className="btn mb-4 cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
                >
                  2 Plays - 100
                  <ArrowForwardIos sx={{ fontSize: 17 }} />
                </button>
                <button
                  onClick={() => handleBuyPlay("plays", 3)}
                  className="btn mb-4 cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
                >
                  3 Plays - 150
                  <ArrowForwardIos sx={{ fontSize: 17 }} />
                </button>
                <button
                  onClick={() => handleBuyPlay("plays", 5)}
                  className="btn mb-4 cursor-pointer flex justify-between items-center border-b border-solid py-4 border-b-grey-border"
                >
                  5 Plays - 250
                  <ArrowForwardIos sx={{ fontSize: 17 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
