import React, { useContext, useEffect } from "react";
import "./newHighScore.css";
import Success_girl from "../../assets/images/Show Love.png";
import clapImage from "../../assets/images/clap.png";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import CardLayout from "../../../../components/cardLayout/CardLayout";
import Modal from "../../../../components/modal/Modal";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import HomeIcon from "@mui/icons-material/Home";
const NewHighScore = ({
  showHomepage = true,
  submitQuestions,
  scorePoint,
  playAgain,
  loading,
  stopSound,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    submitQuestions();
  };
  useEffect(() => {
    const playCount = sessionStorage.getItem("playCount") || 0;
    const quizID = sessionStorage.getItem("quizID");
    sessionStorage.setItem(
      "playStats",
      +playCount + 1
      // JSON.stringify({ playCount: +playCount + 1, id: quizID })
    );
  }, []);
  return (
    <div className="newHighScore">
      <div className="newHighScore-container">
        <div className="newHighScore-hold">
          <div className="newHighScore-wrap">
            <HighScore
              score={scorePoint?.data?.point}
              navigate={navigate}
              playAgain={playAgain}
              stopSound={stopSound}
            />

            {/* <div className="newHighScore-title">
              {scorePoint.highScore === true ? "New High Score" : "SCORE"}
            </div>
            <div className="newHighScore-imgWrap">
              <img src={Success_girl} alt="" />
            </div>
            <div>
              {scorePoint.highScore === true
                ? "Congrats! Your new highscore is"
                : "Congrats! Your score is"}
              <span style={{ color: "#D92B58", fontWeight: "bold" }}>
                {" "}
                {scorePoint?.data?.point || (
                  <span>
                    {" "}
                    <div
                      className="newHighScore-loaderWrap"
                      style={{ marginTop: "20px" }}
                    >
                      {loading && (
                        <span
                          className="loader"
                          style={{ margin: "0 auto" }}
                        ></span>
                      )}
                    </div>
                  </span>
                )}
              </span>
            </div> */}
            {/* <Link to="/qwiz/leaderboard">
              <button onClick={stopSound}>Leaderboard</button>
            </Link>

            <div
              style={{ marginTop: "-20px", cursor: "pointer", color: "green" }}
              onClick={() => {
                stopSound();
                playAgain();
              }}
            >
              Play Again
            </div>
            {showHomepage && (
              <div
                style={{
                  marginTop: "-20px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={() => {
                  stopSound();
                  navigate("/qwiz/homepage");
                }}
              >
                <BsArrowLeft /> <span> Back to Homepage</span>{" "}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHighScore;

export function HighScore({ score, stopSound, navigate, playAgain }) {
  return (
    <Box p={{ xs: 1, md: 3 }}>
      <Box>
        <Typography
          variant="h4"
          align="center"
          mb={1}
          fontWeight={900}
          fontSize={20}
        >
          {" "}
          Well-done, Rockstar
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={126}>
            <Image src={clapImage} color="transparent" />
          </Box>
        </Box>
        <Typography
          variant="body2"
          align="center"
          mb={2}
          fontWeight={500}
          fontSize={20}
        >
          {" "}
          Your Score is <span style={{ fontWeight: 800 }}>{score}</span>
        </Typography>
      </Box>
      <Button
        startIcon={<LeaderboardIcon />}
        variant="contained"
        fullWidth
        color="success"
        sx={{ bgcolor: "#F29F06" }}
        onClick={() => {
          stopSound();
          navigate("/qwiz/leaderboard");
        }}
      >
        See LeaderBoard
      </Button>
      <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button
          startIcon={<RotateLeftIcon />}
          variant="contained"
          sx={{ bgcolor: "#61A641" }}
          onClick={() => {
            stopSound();
            playAgain();
          }}
        >
          Play Again
        </Button>
        <Button
          startIcon={<HomeIcon />}
          variant="contained"
          sx={{ bgcolor: "#F24444" }}
          onClick={() => {
            stopSound();
            navigate("/qwiz/homepage");
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
}
