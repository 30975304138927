import React from "react";

//image & icon
import crown from "../../assets/images/crown.svg";

export default function Leaderboards({ players, isLoading, sortedArray }) {
  function formatDate(date) {
    const dateTime = new Date(date);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      // timeZoneName: 'short',
    };
    return dateTime.toLocaleDateString("en-US", options);
  }

  return (
    <div className="">
      {isLoading ? (
        <span style={{ margin: "0 auto" }} className="loadingstate"></span>
      ) : (
        <div className="leaderboardScreen">
          <div className="winnersSection">
            {sortedArray?.length > 1 && (
              <div className="winners">
                <p className="position" key={sortedArray[1]?.id}>
                  {<div className="position-label">2nd</div>}
                </p>
                <div className="imgCtn">
                  <img
                    src={sortedArray[1]?.subscriber?.avatar}
                    alt="winners avatar"
                    className="winnersAvatar"
                  />
                </div>

                <p className="winnersName">
                  {sortedArray[1]?.subscriber?.display_name}
                </p>
                <p className="winnersName">{sortedArray[1]?.score}</p>
              </div>
            )}
            <div className="winners">
              <p className="position" key={sortedArray[0]?.id}>
                {<div className="position-label">1st</div>}
              </p>
              <img src={crown} alt="crown" />
              <div className="winner">
                <img
                  src={sortedArray[0]?.subscriber?.avatar}
                  alt="winners avatar"
                  className="winnersAvatar"
                />
              </div>
              <p className="winnersName">
                {sortedArray[0]?.subscriber?.display_name}
              </p>
              <p className="winnersName">{sortedArray[0]?.score}</p>
            </div>
            {sortedArray?.length == 3 && sortedArray?.length > 2 && (
              <div className="winners">
                <p className="position" key={sortedArray[2]?.id}>
                  {<div className="position-label">3rd</div>}
                </p>
                <div className="imgCtn">
                  <img
                    src={sortedArray[2]?.subscriber?.avatar}
                    alt="winners avatar"
                    className="winnersAvatar"
                  />
                </div>
                <p className="winnersName">
                  {sortedArray[2]?.subscriber?.display_name}
                </p>
                <p className="winnersName">{sortedArray[2]?.score}</p>
              </div>
            )}
          </div>
          <div className="playersSection">
            {players?.map((player, index) => (
              <div className="players" key={player?.id}>
                <div className="playersPosition">{index + 4}th</div>
                <div className="playersName">
                  {player?.subscriber?.display_name}
                </div>
                <div className="playersPoint">{player?.score}</div>
                <div className="playedDate">
                  {player?.updatedAt === null
                    ? "0:00"
                    : formatDate(player?.updatedAt)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
