import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "@fontsource/poppins";
import {
  Container,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
} from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2.js";

import * as Yup from "yup";
import { useRegistration } from "../../../hooks/api/post";
import { Info } from "@mui/icons-material";
function RegisterForm({ setErrorMessage, setOpenErrorModal }) {
  const registerAPI = useRegistration();
  const navigate = useNavigate();
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name is too Short!")
      .max(70, "Name is too Long!")
      .required("Name is Required"),
    display_name: Yup.string()
      .min(6, "Display Name should be at least 6 characters ")
      .max(30, "Display Name is Too Long!")
      .required("Display Name is Required"),
    user_passport: Yup.string()
      .required("Mobile number is required")
      .matches(
        /^(234)\d{10}$/,
        "Use the international mobile number format that starts with 234"
      ),
  });
  return (
    <Container>
      <Typography
        variant="h3"
        align="center"
        fontSize={28}
        fontWeight={900}
        sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
      >
        Register
      </Typography>
      <Box
        mt={{
          xs: 2,
          md: 0,
        }}
      >
        <Formik
          initialValues={{
            name: "",
            user_passport: "",
            display_name: "",
            email: "",
            accept_term: false,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log({ values });
            registerAPI.mutate(values, {
              onSuccess: (res) => {
                toast.success("Registration Successful");
                // console.log({ femmmmi: res, number });
                const userInfo = JSON.stringify({
                  token: res?.token,
                  userPassport: res?.subcriber?.user_passport,
                  id: res?.subcriber?.id,
                  displayName: res?.subcriber?.display_name,
                  name: res?.subcriber?.name,
                  avatar: res?.subcriber?.avatar,
                });

                sessionStorage.setItem(
                  "quizID",
                  "24df12d9-1116-4b57-a458-c2e39d6fd36a"
                );
                sessionStorage.setItem("ccAuth", userInfo);
                sessionStorage.setItem(
                  "displayName",
                  res?.subcriber?.display_name
                );
                localStorage.setItem("newbie", "yes");
                navigate("../homepage");
              },
              onError: (err) => {
                // Swal.fire({
                //   icon: "error",
                //   title: "Registration Failed",
                //   text: err?.response?.data?.message,
                //   confirmButtonText: "Close",
                //   padding: "10 px",
                //   input: false,
                //   showClass: {
                //     popup: `
                //       animate__animated
                //       animate__fadeInUp
                //       animate__faster
                //     `,
                //   },
                //   hideClass: {
                //     popup: `
                //       animate__animated
                //       animate__fadeOutDown
                //       animate__faster
                //     `,
                //   },
                // });
                setErrorMessage(err?.response?.data?.message);
                setOpenErrorModal((err) => !err);
              },
              onSettled: () => {
                setSubmitting(false);
              },
            });
          }}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form>
              <Box mb={{ xs: 4, md: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body2"
                    mr={1}
                    fontWeight={700}
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: "#707070",
                    }}
                  >
                    Name{" "}
                  </Typography>
                  <span>
                    {" "}
                    <Tooltip
                      arrow
                      enterTouchDelay={0}
                      title="Ensure that the name is the same as your bank as it cannot be changed. "
                    >
                      <Info color="error" />
                    </Tooltip>
                  </span>
                </Box>
                <Box sx={{ color: "error.main" }} mb={1} fontSize={12}>
                  <ErrorMessage name="name" />
                </Box>
                <TextField
                  name="name"
                  placeholder="e.g John Jonas"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </Box>
              <Box mb={{ xs: 4, md: 2 }}>
                <Typography
                  variant="body2"
                  mb={1}
                  fontWeight={700}
                  sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
                >
                  Mobile Number
                </Typography>
                <Box sx={{ color: "error.main" }} mb={1} fontSize={12}>
                  <ErrorMessage name="user_passport" />
                </Box>
                <TextField
                  name="mobile"
                  placeholder="2349021321192"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    setFieldValue("user_passport", e.target.value)
                  }
                />
              </Box>
              <Box mb={{ xs: 4, md: 2 }}>
                <Typography
                  variant="body2"
                  mb={1}
                  fontWeight={700}
                  sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
                >
                  Email
                </Typography>
                <Box sx={{ color: "error.main" }} mb={1} fontSize={12}>
                  <ErrorMessage name="email" />
                </Box>
                <TextField
                  name="email"
                  placeholder="abc@gmail.com"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
              </Box>
              <Box mb={{ xs: 4, md: 2 }}>
                <Typography
                  variant="body2"
                  mb={1}
                  fontWeight={700}
                  sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
                >
                  Display Name
                </Typography>
                <Typography
                  variant="body2"
                  mb={1}
                  fontWeight={500}
                  fontSize={12}
                  sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
                >
                  Display name must be at least 6 characters (Max 30)
                </Typography>

                <TextField
                  name="display_name"
                  placeholder="Johnny092"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    setFieldValue("display_name", e.target.value)
                  }
                />
              </Box>
              <Box
                mb={{ xs: 4, md: 2 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  mr={2}
                  required
                  onChange={(e) =>
                    setFieldValue("accept_term", e.target.checked)
                  }
                />
                <Typography
                  variant="body2"
                  fontWeight={500}
                  fontSize={12}
                  sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
                >
                  By clicking here, I state that I have read and understood the{" "}
                  <Link to="/qwiz/tac">Terms and Conditions </Link>
                </Typography>
              </Box>
              <Box>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="success"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Registering...Please wait" : "Register"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      <Box>
        <Typography
          variant="body2"
          fontWeight={500}
          fontSize={12}
          my={3}
          align="center"
          sx={{ fontFamily: "'Poppins', sans-serif", color: "#707070" }}
        >
          Already have an account?{" "}
          <Link to="../logins" style={{ color: "#61A641" }}>
            Login{" "}
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default RegisterForm;
