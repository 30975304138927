import React from "react";
import Card from "./Card";
import { Box, Stack, Typography } from "@mui/material";
import AlarmIcon from "@mui/icons-material/Alarm";
function TimeCard({ sx, title, time, ...rest }) {
  return (
    <Box sx={sx} {...rest}>
      <Typography variant="h6" mb={2} mt={1} fontSize={12}>
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" sx={{ gap: "8px" }}>
        <AlarmIcon />
        <Typography variant="h5" fontSize={14}>
          {time}
        </Typography>
      </Stack>
    </Box>
  );
}
function TimeRate({
  options = [
    { time: "N/A", title: "average Completion" },
    { time: "N/A", title: "Average Attempts" },
  ],
}) {
  return (
    <Card
      title="Time Rate"
      sx={{ minWidth: "250px", padding: "10px 20px", height: "200px" }}
    >
      <Stack direction="row" gap={2}>
        {options?.map((option, index) => (
          <TimeCard key={index} time={option?.time} title={option?.title} />
        ))}
        {/* <TimeCard time="2m:59s" title="Average Attempts" /> */}
      </Stack>
    </Card>
  );
}

export default TimeRate;
