import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
// import boyIcon from "../../../assets/pointing-bubbles.png";
import boyIcon from "../../../assets/iphone.png";
import { Box, Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PrizePot from "../../../components/prizePot/PrizePot";
import { useLogin } from "../../hooks/api/post";
import { toast } from "react-toastify";
import { useLogPlayerActivity } from "../../hooks/logs/useLogger";

function LandingPage() {
  const navigate = useNavigate();
  const loginAPI = useLogin();
  const logActivity = useLogPlayerActivity();
  const [loginResponse, setLoginResponse]= useState("")

  useEffect(() => {
    logActivity.mutate({
      action_type: "Get Started",
    });
    const user_passport = localStorage.getItem("user_passport");
    const rememberMe = localStorage.getItem("rememberMe");
    if (!!user_passport && rememberMe) {
      console.log("autologin");
      loginAPI.mutate(
        { user_passport: user_passport, service_id: 1 },
        {
          onSuccess: (res) => {
            toast.dismiss();
            toast.success("Login Successful");
            // console.log({ femmmmi: res, number });
            const userInfo = JSON.stringify({
              token: res?.token,
              userPassport: user_passport,
              id: res?.user?.id,
              displayName: res?.user?.display_name,
              name: res?.user?.name,
              ageRange: res?.user?.age_range,
              avatar: res?.user?.avatar,
            });

            if (res.user.display_name === "null") {
              sessionStorage.setItem("closeCreate", false);
            }
            sessionStorage.setItem(
              "quizID",
              "24df12d9-1116-4b57-a458-c2e39d6fd36a"
            );
            sessionStorage.setItem("ccAuth", userInfo);
            sessionStorage.setItem("displayName", res?.user?.display_name);
            if (res?.user?.first_login === 0) {
              sessionStorage.setItem("newbie", "no");
            } else {
              sessionStorage.setItem("newbie", "yes");
              localStorage.setItem("hideHelp", "false");
            }
            res?.user?.first_login === 1
              ? navigate("../started")
              : navigate("../homepage");
          },
          onError: (err) => {
            toast.dismiss();
            toast.error(err.response.data?.message);
            if (
              err.response.data?.message === "Number is not in correct format"
            ) {
              // setLoginResponse("Incorrect Format");
            }
            if (
              err.response.data?.message === "You don't have active subscripton"
            ) {
              // setLoginResponse("No Subscription");
              // window.location.href =
              //   "http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web";
              navigate("../waiting");
            }

            //new implementation
            // navigate("../started");
          },
        }
      );
    }
  }, []);

  return (
    <Layout>
      <Container>
        <PrizePot />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img style={{ maxWidth: "100%" }} src={boyIcon} alt="Happy Boy" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p className="text-center mb-3 text-lg">
            One day <span className="text-[#61A641] font-semibold">FREE</span>{" "}
            trial for new subscribers!
          </p>
          <Box mt={0} sx={{ width: "100%", maxWidth: "236px" }}>
            <Link to="logins">
              <Button
                variant="contained"
                fullWidth
                color="error"
                sx={{
                  height: "40px",
                  background: "#F24444",
                  borderRadius: "8px",
                }}
              >
                Get Started
              </Button>
            </Link>
          </Box>
          {/* <Box mt={4} sx={{ width: "100%", maxWidth: "236px" }}>
            <Link to="register">
              <Button
                color="success"
                variant="outlined"
                fullWidth
                sx={{ height: "40px", borderRadius: "8px" }}
              >
                Register
              </Button>
            </Link>
          </Box> */}
        </Box>
      </Container>
    </Layout>
  );
}

export default LandingPage;
