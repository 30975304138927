import React from 'react'
import { useNavigate } from 'react-router-dom'

function OptOut() {
    const navigate= useNavigate()
    const userPassport= localStorage.getItem("user_passport")
const handleLogout=()=>{
localStorage.removeItem("user_passport")
localStorage.removeItem("rememberMe")
localStorage.removeItem("hideHelp")
navigate("/qwiz/logins")
}
  return (
    <div className='mt-3  max-w-[236px]'>
    <div className='flex  flex-wrap '>
        <p className="">Is your number {userPassport}?</p>
      <button className='btn btn-secondary ' onClick={handleLogout}>
Log in with different number
      </button>
    </div>
    </div>
  )
}

export default OptOut
