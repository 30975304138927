import { io } from "socket.io-client";
import { useEffect } from "react";

export default function Socket({ setReloader, usersDigit, quizID }) {
  // const socket = io("https://gameapi.9ijakids.com");

  // useEffect(() => {
  //   // console.log({ socket: socket });

  //   socket.on("connect", () => {
  //     // console.log({ socketId: socket.id });
  //   });
  // }, [socket]);

  // socket.on(`successSub-${usersDigit}`, (...args) => {
  //   setReloader((e) => e + 1);

  // });

  // leaderboard
  // socket.on(`leaderboard-${quizID}`, (...args) => {
  //   setReloader((e) => e + 1);

  // });
  return <></>;
}
