import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import Button from "../../components/QuizButton/Button";
import ViewBankAccounts from "./ViewBankAccount";
import Modal from "../../components/modal/Modal";
import DropDown from "../../components/DropDown/DropDown";
import { useFetchAllBanks, useFetchUserAccount } from "../../hooks/api/fetch";
import {
  useAddAccount,
  useEditAccount,
  useConfirmPinStatus,
} from "../../hooks/api/post";
import AuthCode from "react-auth-code-input";
import { toast } from "react-toastify";
import successImage from "../../../Mobi2.0/assets/images/Briefcase.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";

export default function Winning() {
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const userid = userID?.id;
  const [active, setActive] = useState("Add-Account");
  const [accountName, setAccountName] = useState();
  const [enterPin, setEnterPin] = useState("");
  const [selectedBankCode, setSelectedBankCode] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const fetchAllAvailableBank = useFetchAllBanks();
  const AddAcountDetails = useAddAccount();
  const [userPin, setUsersPin] = useState();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const fetchAllUsersAddedAccount = useFetchUserAccount({ userid });
  const accountID = fetchAllUsersAddedAccount?.data?.account?.id;
  const EditUserAccountDetails = useEditAccount(accountID);
  const confirmUsersPinStatus = useConfirmPinStatus();
  const navigate = useNavigate();

  const handleActive = (buttonid) => {
    setActive(buttonid);
  };

  //CHECK IF USER ALREADY CREATED A PIN ELSE NAVIGATE TO SECURITY
  useEffect(() => {
    const checkUserPinStatus = async () => {
      try {
        const response = await confirmUsersPinStatus.mutateAsync({
          user_id: userID.id,
        });
        if (response?.message !== "Pin Set") {
          navigate("/qwiz/security");
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkUserPinStatus();
  }, []);

  // HANDLE UPDATE ACCOUNT
  const updateAccount = (buttonid) => {
    setActive(buttonid);
    setOpen(true);
  };

  // HANDLE CLOSE MODAL
  function handleCloseModal() {
    setOpen(false);
  }
  function handleCloseSuccessModal() {
    setSuccess(false);
  }
  // GET PIN INPUT
  const handlelock = (id) => {
    setUsersPin(id);
  };

  const handleEnteredPin = (res) => {
    setEnterPin(res);
  };
  // GET BANK NAME
  const SelectedBankName = fetchAllAvailableBank?.data?.find(
    (items) => items.code === selectedBankCode
  );
  const NewUserPin = userPin?.toString();

  const returnToHome = () => {
    navigate("/qwiz/homepage");
  };

  //HANDLE ADD ACCOUNT
  const handleBankAccount = () => {
    const response = AddAcountDetails.mutate(
      {
        user_id: userID.id,
        pin: enterPin,
        bank_code: selectedBankCode,
        account_number: accountNumber,
        bank_name: SelectedBankName?.name,
      },
      {
        onSuccess: () => {
          setSuccess(true);
          setAccountName("");
          setAccountNumber("");
          setEnterPin("");
        },
        onError: (error) => {
          toast.dismiss();
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        },
      }
    );
  };

  // Handle view account screen
  const viewAccountPage = () => {
    if (NewUserPin?.length === 4) {
      setOpen(false);
      setActive("View-Account");
    } else {
      return;
    }
  };
 

  // EDIT BANK DETAILS
  const handleEditBankDetails = () => {
    const response = EditUserAccountDetails.mutate(
      {
        pin: userPin,
        bank_code: selectedBankCode,
        account_number: accountNumber,
        bank_name: SelectedBankName?.name,
      },
      {
        onSuccess: () => {
          setSuccess(true);
          setAccountName("");
          setAccountNumber("");
          setUsersPin("");
        },
        onError: (error) => {
          toast.dismiss();
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_LEFT,
          });

          
        },
      }
    );
  };

  // Banks DropDown option
  const options = fetchAllAvailableBank?.data?.map((bankList) => ({
    value:bankList.code,
    label: bankList.name,
  }));

  const handleChange = (data) => {
    // setFilter(data);
    setSelectedBankCode(data?.value)
  };

 

  return (
    <AuthLayout>
      <main className="paymentCtn">
        <h1 className="paymentHeading">Winnings</h1>
        <div className="subscriptionAct">
          <Button
            type="submit"
            handleclick={() => handleActive("Add-Account")}
            btntype={`${active === "Add-Account" ? "planActive" : "plan"}`}
          >
            {edit ? "Edit Account" : "Add Account"}
          </Button>
          <Button
            type="submit"
            // handleclick={() => handleActive("View-Account")}
            handleclick={() => updateAccount()}
            btntype={`${active === "View-Account" ? "planActive" : "plan"}`}
          >
            View Account
          </Button>
        </div>
        {active !== "View-Account" ? (
          <div className="winningsection" key="add-account">
            <div className="winningInputctn">
              <label className="winnningLabel"> Account Name</label>
              <input
                type="text"
                placeholder="John Oluwakemi Okafor"
                className="winningInput"
                // value={edit ? userID?.name : accountName}
                value={userID?.name}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
            <div className="winningInputctn">
              <div className="winnningLabel">Choose a Bank</div>
              <DropDown placeholder="Choose a Bank" options ={options} handleChange={handleChange}/>
              
            </div>
            <div className="winningInputctn">
              <label className="winnningLabel">
                {" "}
                Enter account number
                <input
                  type="number"
                  placeholder="10-digit account number"
                  className="winningInput"
                  min={1}
                  max={10}
                  value={accountNumber}
                  defaultValue={
                    edit
                      ? fetchAllUsersAddedAccount?.data?.account?.account_number
                      : accountNumber
                  }
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </label>
            </div>
            {edit ? (
              ""
            ) : (
              <div className="AccPin">
                <div className="winnningLabel">Enter PIN </div>
                <AuthCode
                  allowedCharacters="numeric"
                  length="4"
                  inputClassName="addAccSetPinForm"
                  placeholder="*"
                  onChange={handleEnteredPin}
                />
                {/* </form> */}
              </div>
            )}
            <div className="AccPinSection">
              {edit ? (
                <Button
                  type="submit"
                  btntype={`${
                    EditUserAccountDetails.isLoading ? "disabled" : "active"
                  }`}
                  handleclick={() => handleEditBankDetails()}
                  disabled={EditUserAccountDetails.isLoading}
                >
                  {EditUserAccountDetails.isLoading ? (
                    <div className="subLoadingCtn">
                      Please wait
                      <div className="subloadingstate"></div>
                    </div>
                  ) : (
                    " Update Bank Account"
                  )}
                </Button>
              ) : (
                <Button
                  type="submit"
                  btntype={`${
                    AddAcountDetails.isLoading ? "disabled" : "active"
                  }`}
                  handleclick={() => handleBankAccount()}
                  disabled={AddAcountDetails.isLoading}
                >
                  {AddAcountDetails.isLoading ? (
                    <div className="subLoadingCtn">
                      Please wait
                      <div className="subloadingstate"></div>
                    </div>
                  ) : (
                    " Add Bank Account"
                  )}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <ViewBankAccounts
            key="view-account"
            fetchAllUsersAddedAccount={fetchAllUsersAddedAccount}
            userid={userID?.id}
            userName={userID?.name}
            setUsersPin={setUsersPin}
            setOpen={setOpen}
            open={open}
            setEdit={setEdit}
            setActive={setActive}
          />
        )}
        <Modal open={open} handleClose={handleCloseModal}>
          <div className="viewAccountSecurityModal">
            <h3 className="viewAccText">Enter Pin</h3>
            <div className="AccPinSection">
              <AuthCode
                allowedCharacters="numeric"
                length="4"
                inputClassName="setPinForm"
                placeholder="*"
                onChange={handlelock}
              />
            </div>
            <div className="modalBtnCtn">
              <Button
                type="submit"
                btntype="modal"
                handleclick={() => viewAccountPage()}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Modal>

        <Modal open={success} handleClose={handleCloseSuccessModal}>
          <div className="successModal">
            <h3 className="viewAccText">
              {EditUserAccountDetails?.isSuccess
                ? "Account updated successfully"
                : "Account Created successfully"}
            </h3>
            <img src={successImage} alt="success image" className="imgStyle" />
            <div className="modalBtnCtn">
              <Button type="submit" btntype="modal" handleclick={returnToHome}>
                <HomeOutlinedIcon style={{ marginRight: "5px" }} />
                Back to Homepage
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    </AuthLayout>
  );
}
