function extractProps(props) {
  props.map((data) => {
    data.game_file_path = data.quizPath;
    data.game_image_path = data.avatar;
    data.game_description = data.quiz_description;
    data.game_title = data.quiz_title;
    return data;
  });
}
export function convertQuizToGame(payload) {
  let res = { ...payload };

  res.allQuiz.map((data) => (data.game_image_path = data.avatar));
  //   res.popularQuiz.map((data) => {
  //     data.game_file_path = data.quizPath;
  //       data.game_image_path = data.avatar;

  //     return data;
  //   });
  extractProps(res.allQuiz);
  extractProps(res.popularQuiz);
  extractProps(res.topQuiz);
  //   res.topQuiz.map((data) => (data.game_image_path = data.avatar));
  res.allGames = res.allQuiz;
  res.popularGames = res.popularQuiz;
  res.topGames = res.topQuiz;
  console.log({ res });
  //   return res;
  return { data: res, success: res.success };
}
