import { useGetUsersNumber } from "../../hooks/api/fetch";
import { useCheckMaketingTrafficName, useCheckMaketingTrafficRecord } from "../../hooks/api/post";
import loginImage from "../../../Mobi2.0/assets/images/login-image.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CircleLoad from "../../components/Spinner/CircleLoad";

export default function SecureDlink() {
  const fetchUsersNumber = useGetUsersNumber();
  const checkMarketingTraffic = useCheckMaketingTrafficRecord();
  const navigate = useNavigate();
  const { code } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const trackerId = urlSearchParams.get("trackerId");
  const checkMarketingName = useCheckMaketingTrafficName({name:code});

  // check marketing Traffic
  const handleCheckMarketingTraffic = () => {

    const response = checkMarketingTraffic.mutate(
      {
        unique_code: code,
        phone: fetchUsersNumber?.data?.msisdn,
        trackerId: trackerId,
      },
      {
        onSuccess: (res) => {
          // window.location.href = `http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web?trxId=${trackerId}`;
        if(!!checkMarketingName.data?.company?.name){

          window.location.href = `https://ng-app.com/VasDigimobility/9ijakidstriviaquiz-Landing-en-doi-web?origin_banner=1&trfsrc=${checkMarketingName.data?.company?.name}&trxId=${trackerId}`;
        }
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  useEffect(() => {
    if (
      fetchUsersNumber?.data?.msisdn !== "" &&
      fetchUsersNumber?.data?.msisdn !== undefined &&
      fetchUsersNumber?.data?.msisdn !== null
    ) {
      //  handleCheckMarketingTraffic()
    }
    handleCheckMarketingTraffic();
  }, [code, fetchUsersNumber?.data?.msisdn,checkMarketingName.data?.company?.name]);

  // if (checkMarketingTraffic.isLoading) {
  //   return <>Loading...Please wait</>;
  // }
  return (
    <>
      <CircleLoad loading={true} color={"#aeeaec"} size={50} />
    </>
  );
  return (
    <main className="paymentCtn">
      <h1 className="paymentHeading" data-testid="subscriptionText">
        Direct Subscription
      </h1>
      <div className="secureDlinkImgCtn">
        <img src={loginImage} alt="loginImage" className="secureLinkImg" />
      </div>

      <div className="paymentType">
        <Link
          to={`http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web?trxId=${trackerId}`}
          className="secureDlink"
        >
          Daily{" "}
        </Link>
        <Link
          to={`http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-168-Yes-23410220000025839-web?trxId=${trackerId} `}
          className="secureDlink"
        >
          Weekly{" "}
        </Link>
      </div>
    </main>
  );
}
